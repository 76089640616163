import React from "react";
import styles from "./urm.module.css"
const ChatURM = props => {
    return (
        <div className={[styles.chat_container]} id="chatContainer">
        <div className={[styles.chat_header]} onclick="toggleChat()">Contact DIE officer or Admin (1) </div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender] }>chat:</span>
                <span className={[styles.message] }>Type Admin/DieOfficer to Connect</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message" />
            <button onclick="sendMessage()">Send</button>
        </div>
    </div>
    );
};
export default ChatURM