// ProtectedRoute.js

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserDataFromLocalStorage } from './localStorageUtils';


const ProtectedRoute = ({ allowedRoles }) => {
  const userData = getUserDataFromLocalStorage();
  if (userData) {
    const isAuthenticated = userData.user_id && userData.role
    const hasRequiredRole = allowedRoles == userData.role
    if (isAuthenticated && hasRequiredRole) {
      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  }
  else {
    <Navigate to="/login" />;
  };
}

export default ProtectedRoute;
