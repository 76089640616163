import React, { useState } from "react";
import banner from "../images/hiring.jpg";
import Header from "../layout/header";
import styles from "../layout/home.module.css"
import Footer from "../layout/footer";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from "axios";
const Home = () => {
  const initialValues = {
    email: '',
    message: '',
    name: ''
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.message) {
      errors.message = 'Message is required';
    }
    if (!values.name) {
      errors.name = 'Name is required';
    }
    return errors;
  };
  const handleSubmit = async (values) => {
    console.log("here")
    const valuesWithDefaultRole = {
      ...values
    };
    try {
      const response = await axios.post('/sendmessage.php', valuesWithDefaultRole);
      if (response.data.user_id) {
      } else {
        alert("An error occurred.");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
  return (
    <div>
      <Header />
      <div className={styles.center}  >
        <img src={banner} className={[styles.banner]} />
      </div>
      <section>
        <div className={styles.container}>
          <h1>Apply Now!</h1>
          <p>
            Applications are open for Underrepresented Minority (URM) Candidates for PhD studies, postdoc, and Faculty positions. URM candidates are encouraged to take advantage of these opportunities and apply as they play a crucial role in building a more inclusive and representative future for academia.
          </p>
          <p>
            We actively encourage and welcome applications from individuals belonging to URM groups.
          </p>
        </div>
      </section>

      <section id="about_us">
        <div className={styles.container}>
          <h1>About Us</h1>
          <ul>
            <li>Underrepresented minority (URM) candidates bring unique perspectives and experiences to academia, enriching research and teaching environments. URM candidates contribute to the diversity and inclusivity of academic institutions, fostering a more representative and equitable community. By actively seeking out and recruiting URM candidates, institutions demonstrate their commitment to creating an inclusive academic environment. URM candidates often face systemic barriers and underrepresentation in academia, making targeted outreach and support crucial.</li>
          </ul>
        </div>
      </section>

      <section id="services">
        <div className={styles.container}>
          <h1>Services</h1>
          <ul>
            <li>As a candidate, you can chat with the admin or provide feedback about this website. You can search for jobs or apply to jobs and receive notifications related to your profile on this website.</li>
            <li>As an Admin, you can view and collect reports. The website can generate a report of successful matches between URM candidates and academia, which can be shared with interested parties, such as universities, funding agencies, or government organizations. The report can include statistics on the number and type of matches, as well as feedback and reviews from both the URM candidates and academia.</li>
            <li>As a recruiter or DEI officer, you can view the list of job applicants or change the status of the job. You can contact the candidate.</li>
          </ul>
        </div>
      </section>

      <section id="login">
        <div className={styles.container}>
          <h1>Existing User?</h1>
          <a href="login" className={styles.button}>Log In</a>

          <h1>New User?</h1>
          <a href="Registermain" className={styles.button}>Register</a>
        </div>
      </section>

      <section id="contact_us">
        <div className={styles.container}>
          <h1>Contact Us</h1>
          <div className={styles.contactform}>
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <Form>
                  <div>
                    <label htmlFor="name">Your Name</label>
                    <Field type="name" id="name" name="name" />
                    <ErrorMessage name="name" component="div" className="error_message" />
                  </div>
                  <div>
                    <label htmlFor="email">Your Email</label>
                    <Field type="email" id="email" name="email" />
                    <ErrorMessage name="email" component="div" className="error_message" />
                  </div>
                  <div>
                    <label htmlFor="message">Your Message</label>
                    <Field type="message" id="message" name="message" />
                    <ErrorMessage name="message" component="div" className="error_message" />
                  </div>
                  <button type="submit" className={styles.button}>Submit</button>
                </Form>
              )}
            </Formik>
          
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};
export default Home;
