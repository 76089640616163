import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderDei from "./deiheader";
import ChatDei from "./deichat";
import styles from "./dei.module.css"
const Complaints = () => {
    return (
        <div id="home">
            <HeaderDei></HeaderDei>
            <br />
            <br />
            <h1 className= {[styles.center] }>Complaints</h1>
            <div className= {[styles.container] }>
                <form>
                    <label htmlFor="name">Candidate name:</label>
                    <input type="text" id="name" name="name" value="Joseph" required="" /><br /><br />

                    <label htmlFor="complaint">Complaint:</label>
                    <textarea cols="100" rows="3">"I have some concerns about the hiring process for this DEI offer. It feels like there is a lack of transparency in how candidates are evaluated and selected. I'm not sure what criteria are being used to assess my qualifications and experiences, which makes it difficult for me to gauge my chances of being hired. I believe transparency is crucial for creating a fair and inclusive hiring process."</textarea><br /><br />


                    <label htmlFor="experience">Resolve Complaint:</label>
                    <textarea cols="100" rows="3"></textarea><br /><br />



                    <input type="submit" value="Resolve" />
                </form>


            </div>
            <div className= {[styles.container] }>
                <form>
                    <label htmlFor="name">Candidate name:</label>
                    <input type="text" id="name" name="name" value="Josuah" required="" /><br /><br />

                    <label htmlFor="complaint">Complaint:</label>
                    <textarea cols="100" rows="3">""I must express my frustration about what I perceive as tokenism or a superficial commitment to diversity and inclusion in this DEI offer. It seems like the company is solely focused on meeting diversity quotas rather than genuinely valuing diverse candidates and providing equal opportunities. I want to be evaluated based on my skills, qualifications, and experiences, rather than just being seen as a checkbox for diversity. I believe in the importance of true inclusion and equal treatment for all candidates.""</textarea><br /><br />


                    <label htmlFor="experience">Resolve Complaint:</label>
                    <textarea cols="100" rows="3"></textarea><br /><br />



                    <input type="submit" value="Resolve" />
                </form>


            </div>
            <br /><br />
            <br /><br />
           <ChatDei></ChatDei>
        </div>
    );
};
export default Complaints;
