import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const URMCandidate = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div>
    <div className={[styles.candidatecontainer]}>
        <section className={[styles.search_filter]}>
            <h2>Search and Filter URM Candidates</h2>
            <form id="search-form">
                <input type="text" placeholder="Search by name, field of study, or institution"/>
                <button type="submit">Search</button>
            </form>
            <div className={[styles.filter_options]}>
                <label for="demographic">Demographic:</label>
                <select id="demographic">
                    <option value="">All</option>
                </select>
                <label for="education">Education:</label>
                <select id="education">
                    <option value="">All</option>
                </select>

                <label for="research">Research Experience:</label>
                <select id="research">
                    <option value="">All</option>
                </select>
            </div>
        </section>

        <section className={[styles.candidate_list]}>
            <h2>URM Candidate Profiles</h2>
            <ul className={[styles.candidates]}>
            </ul>
        </section>
    </div>
    <div className={[styles.faculty_table]}>
          <table className={[styles.candidate_table]}>
          <tbody><tr>
            <th>Candidate ID</th>
            <th>Name</th>
            <th>Qualification</th>
            <th>year</th>
            <th>Demographic</th>
            <th>Research Experience</th>
            <th>feedback</th>
            <th>message</th>
          </tr>
          <tr>
            <td>1</td>
            <td>John Doe</td>
            <td>Bachelors</td>
            <td>2022</td>
            <td>Texas</td>
            <td>1</td>
            <td><a href="FeedbackURM">feedback</a></td>
            <td><a href="MessageURM">message</a></td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jane Smith</td>
            <td>Bachelors</td>
            <td>2022</td>
            <td>california</td>
            <td>2</td>
            <td><a href="FeedbackURM">feedback</a></td>
            <td><a href="MessageURM">message</a></td>
          </tr>
          <tr>
            <td>3</td>
            <td>Marin Johnson</td>
            <td>Bachelors</td>
            <td>2022</td>
            <td>Austin</td>
            <td>1</td>
            <td><a href="FeedbackURM">feedback</a></td>
            <td><a href="MessageURM">message</a></td>
          </tr>
          <tr>
            <td>4</td>
            <td>Dominique Ria</td>
            <td>Bachelors</td>
            <td>2022</td>
            <td>Texas</td>
            <td>1</td>
            <td><a href="FeedbackURM">feedback</a></td>
            <td><a href="MessageURM">message</a></td>
          </tr>
          </tbody></table>
    </div>
</div>
    <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default URMCandidate;
