import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const Noofjobs = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.job_application]}>
                <h2>Job Applications List</h2>
                <ul id="applications-list">
                    <li>
                        <div className={[styles.job_title]}>Professor</div>
                        <div className={[styles.job_meta]}>Viewed: 30 | Applied: 5</div>
                        <div className={[styles.job_actions]}>
                            <div className={[styles.candidate]}>
                                <span>John Doe</span>
                                <button className={[styles.accept]}>Accept</button>
                                <button className={[styles.reject]}>Reject</button>
                                <button className={[styles.feedback]}><a className={[styles.home_section_url]} href="FeedbackURM">Feedback</a></button>
                                <button className={[styles.sendmessage]}><a className={[styles.home_section_url]} href="MessageURM">Message</a></button>
                            </div>
                            <div className={[styles.candidate]}>
                                <span>Jane Smith</span>
                                <button className={[styles.accept]}>Accept</button>
                                <button className={[styles.reject]}>Reject</button>
                                <button className={[styles.feedback]}><a className={[styles.home_section_url]} href="FeedbackURM">Feedback</a></button>
                                <button className={[styles.sendmessage]}><a className={[styles.home_section_url]} href="MessageURM">Message</a></button>
                            </div>
                        </div>
                    </li><li>
                        <div className={[styles.job_title]}>Assitant Professor</div>
                        <div className={[styles.job_meta]}>Viewed: 40 | Applied: 19 </div>
                        <div className={[styles.job_actions]}>
                            <div className={[styles.candidate]}>
                                <span>James Bond</span>
                                <button className={[styles.accept]}>Accept</button>
                                <button className={[styles.reject]}>Reject</button>
                                <button className={[styles.feedback]}><a className={[styles.home_section_url]} href="FeedbackURM">Feedback</a></button>
                                <button className={[styles.sendmessage]}><a className={[styles.home_section_url]} href="MessageURM">Message</a></button>
                            </div>
                            <div className={[styles.candidate]}>
                                <span>Sunny Rose </span>
                                <button className={[styles.accept]}>Accept</button>
                                <button className={[styles.reject]}>Reject</button>
                                <button className={[styles.feedback]}><a className={[styles.home_section_url]} href="FeedbackURM">Feedback</a></button>
                                <button className={[styles.sendmessage]}><a className={[styles.home_section_url]} href="MessageURM">Message</a></button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default Noofjobs;
