import React, { useState } from "react";
import styles from "./urm.module.css"
import Footer from "../../layout/footer";
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import RoleConstant from "../../Constant";
const RegisterURM = () => {
    const navigate = useNavigate();
      const initialValues =
      {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          PASSWORD: '',
          demography: '',
          ethinictiy: '',
          nationality: '',
          studyfield: '',
          education: '',
          role: RoleConstant.Candidate
      };
      const validateForm = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'First name is required';
        }

        if (!values.lastName) {
            errors.lastName = 'Last name is required';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!values.phone) {
            errors.phone = 'Phone is required';
        }

        if (!values.PASSWORD) {
            errors.PASSWORD = 'Password is required';
        }

        if (!values.demography) {
            errors.demography = 'Demography is required';
        }

        if (!values.ethinictiy) {
            errors.ethinictiy = 'Ethnicity is required';
        }

        if (!values.nationality) {
            errors.nationality = 'Nationality is required';
        }

        if (!values.studyfield) {
            errors.studyfield = 'Study field is required';
        }

        if (!values.education) {
            errors.education = 'Education is required';
        }

        return errors;
    };
    const handleSubmit = async (values) => {
        console.log("here")
        const valuesWithDefaultRole = {
            ...values
        };
        try {
            const response = await axios.post('/urmcandidate.php', valuesWithDefaultRole);
            if (response.data.success) {
                alert("Account Created Successfully");
                navigate("/login")
            } else {
                alert("An error occurred:");
            }
        } catch (error) {
            alert('An error occurred:', error);
        }
    };
    return (
        <div id="home">
            <nav className={[styles.nav]}>
                <span className={[styles.logo]}>URM Application</span>
                <a href="/">Home</a>
            </nav>
            <div className={[styles.body_height]}>
                <h1 className={[styles.center]}>Candidate Registration Page</h1><br /><br />
                <div className={[styles.container]}>
                    <h1>Please Enter all the below mentioned details</h1>
                    <Formik
                        initialValues={initialValues}
                        validate={validateForm}
                        onSubmit={handleSubmit}
                    >
                           {({ values, handleChange, handleBlur, handleSubmit }) => (
                            <Form>
                                <div>
                                    <label htmlFor="firstName">First Name</label>
                                    <Field type="text" id="firstName" name="firstName" />
                                    <ErrorMessage name="firstName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field type="text" id="lastName" name="lastName" />
                                    <ErrorMessage name="lastName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="email">Email</label>
                                    <Field type="text" id="email" name="email" />
                                    <ErrorMessage name="email" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="phone">Phone</label>
                                    <Field type="text" id="phone" name="phone" />
                                    <ErrorMessage name="phone" component="div" className="error_message" />
                                </div>
                                <div>
                                    <label htmlFor="PASSWORD">Password</label>
                                    <Field type="PASSWORD" id="PASSWORD" name="PASSWORD" />
                                    <ErrorMessage name="PASSWORD" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="demography">Demography</label>
                                    <Field type="text" id="demography" name="demography" />
                                    <ErrorMessage name="demography" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="ethinictiy">Ethnicity</label>
                                    <Field type="text" id="ethinictiy" name="ethinictiy" />
                                    <ErrorMessage name="ethinictiy" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="nationality">Nationality</label>
                                    <Field type="text" id="nationality" name="nationality" />
                                    <ErrorMessage name="nationality" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="studyfield">Study Field</label>
                                    <Field type="text" id="studyfield" name="studyfield" />
                                    <ErrorMessage name="studyfield" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="education">Education</label>
                                    <Field type="text" id="education" name="education" />
                                    <ErrorMessage name="education" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="coverletter">Cover Letter</label>
                                    <Field type="file" id="coverletter" name="coverletter"  />
                                    <ErrorMessage name="coverletter" component="div" className="error_message" />
                                </div>
                                <div>
                                    <label htmlFor="resume">Resume</label>
                                    <Field type="file" id="resume" name="resume" />
                                    <ErrorMessage name="resume" component="div" className="error_message" />
                                </div>
                                <br /><input type="submit" value="Register As Candidate" />   <br />
                            </Form>
   )}
                    </Formik>
                    
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};
export default RegisterURM;
