import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderRecruiter from "./headerrecrutier";
import FooterHR from "./footerhr";
import styles from "./hr.module.css"
const AboutRecruter = () => {
    return (
        <div id="home">
            <HeaderRecruiter></HeaderRecruiter>
            <br />
            <br />
            <div className= {[styles.container] }>

                <h2>Recruiter Agency Details</h2>
                <p>Agency Name: XYZ Recruiting Agency</p>
                <p>Contact Person: John Smith</p>
                <p>Email: john@example.com</p>
                <p>Phone: +1 123-456-7890</p>

            </div>


            <div className= {[styles.container] }>
                <h2>Positions Recruiting details</h2>
                <table>

                    <tr>
                        <th>Position</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>Software Engineer</td>
                        <td>Develops and maintains software applications.</td>
                    </tr>
                    <tr>
                        <td>Web Developer</td>
                        <td>Designs and builds websites and web applications.</td>
                    </tr>
                    <tr>
                        <td>Data Analyst</td>
                        <td>Analyzes and interprets complex data to provide insights.</td>
                    </tr>
                    <tr>
                        <td>Marketing Specialist</td>
                        <td>Executes marketing campaigns and strategies.</td>
                    </tr>
                    <tr>
                        <td>Human Resources Manager</td>
                        <td>Oversees HR operations and employee relations.</td>
                    </tr>
                </table>
            </div>


            <div className= {[styles.container] }>
                <h2>Clients</h2>
                <table align="center">
                    <tr ALIGN="center">
                        <th>Client Details</th>

                    </tr>
                    <tr ALIGN="center">
                        <td>XYZ Academic Institution</td>
                    </tr>
                    <tr>
                        <td>ABC Academic Instution</td>

                    </tr>
                    <tr ALIGN="center">
                        <td>PQR Academic Instution</td>

                    </tr>
                </table>
            </div>
            <FooterHR></FooterHR>
        </div>
    );
};
export default AboutRecruter;
