import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderRecruiter from "./headerrecrutier";
import FooterHR from "./footerhr";
import styles from "./hr.module.css"
const HomeRecrutier = () => {
  return (
    <div id="home">
      <HeaderRecruiter></HeaderRecruiter>
      <h1 className={[styles.center]}>Recruiter Dashboard</h1>
      <br /><br />
      <div className={[styles.container]} >
        <form className={[styles.center]}><a href="AddJobRecruter"> <input type="button" value="Add Job Postings" /> </a>

          <a href="ViewJobRecruter"> <input type="button" value="View Job Applicants" /> </a>
        </form>
      </div>

      <br></br>
      <h2 className={[styles.center]} >Matching Candidate List</h2>
      <br></br>
      <div class="big_container container_table">
        <table>
          <tr>
            <th>Candidate Name</th>
            <th>Job Role</th>
            <th>Skills</th>
            <th>Experience </th>
            <th>Application Date</th>
            <th>Preferred Location</th>
            <th>Contact</th>
          </tr>


          <tr>
            <td>Joseph</td>
            <td>Junior Software Developer</td>
            <td>Knowledge in Java</td>
            <td> 2 years </td>
            <td>25-08-2023</td>
            <td>Remote</td>
            <td><form><a href="ChatRecruter"> <input type="button" value="contact" /></a></form></td>
          </tr>


          <tr>
            <td>Joseph</td>
            <td>Data Analyst</td>
            <td>Knowledge in Big Data</td>
            <td> 2 years </td>
            <td>25-08-2023</td>
            <td>Remote</td>
            <td><form><a href="ChatRecruter"> <input type="button" value="contact" /></a></form></td>
          </tr>

          <tr>
            <td>Joseph</td>
            <td>Marketing</td>
            <td>Knowledge in Marketing Field</td>
            <td> 2 years </td>
            <td>31-08-2023</td>
            <td>Remote</td>
            <td><form><a href="ChatRecruter"> <input type="button" value="contact" /></a></form></td>
          </tr>

          <tr>
            <td>Joseph</td>
            <td>Hardware Engineer</td>
            <td>Knowledge in CAD</td>
            <td> 7 years </td>
            <td>10-09-2023</td>
            <td>Remote</td>
            <td><form><a href="ChatRecruter"> <input type="button" value="contact" /></a></form></td>
          </tr>

        </table>

        <br /><br />
      </div>
      <h2 className={[styles.center]}>Type of URM candidates Interested with Location Preferences</h2>
      <section>
        <div className={[styles.container]}>
          <form>
            Job Title: Software Engineer <br /><br />
            Job Role: Develop and maintain software applications<br /><br />
            Job Description: Responsible for designing, coding, testing, and debugging software applications. Collaborate with cross-functional teams to analyze user requirements and implement solutions. <br /><br />
            Skills Required: Proficiency in programming languages like Java, C++, or Python. Strong problem-solving and analytical skills. Knowledge of software development methodologies and tools. <br /><br />
            Experience Required: 2+ years of experience in software development.
            Recruiter Name: Sana <br /><br />
            Last Application Date: 13/02/2020 <br /><br />
            Preffered Location:ON-SITE<br /><br />

          </form>

        </div>
      </section>
      <section>
        <div className={[styles.container]}>
          <form>
            Job Title: Marketing Manager <br /> <br />
            Job Role: Develop and execute marketing strategies <br /><br />
            Job Description: Plan, coordinate, and execute marketing campaigns to promote products or services. Conduct market research, analyze data, and identify target audience. Manage marketing budgets and measure campaign effectiveness. <br />
            Skills Required: Strong communication and interpersonal skills. Knowledge of digital marketing channels and social media platforms. Analytical skills to interpret market trends and customer insights. <br /><br />
            Experience Required: 5+ years of experience in marketing or related field.
            Recruiter Name: James <br /><br />
            Last Application Date: 13/02/2020 <br /><br />
            Preffered Location:ON-SITE/Remote<br /><br />
          </form>
        </div>
      </section>

      <FooterHR></FooterHR>
    </div>
  );
};
export default HomeRecrutier;
