import Home from "./componets/home";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Provider } from 'react-redux';
import Login from "./componets/login";
import ForgotPassword from "./componets/forgotpassword";
import Registermain from "./componets/registermain";
import RegisterDei from "./componets/dei/registerdei";
import DashboardDei from "./componets/dei/dashboarddei";
import AddNewJob from "./componets/dei/addnewjob";
import ViewJonAppliciants from "./componets/dei/viewjobapplication";
import Reports from "./componets/dei/reports";
import Complaints from "./componets/dei/complaints";
import RegisterRecruiter from "./componets/recruiter/register";
import HomeRecrutier from "./componets/recruiter/dashboardrecrutier";
import AddJobRecruter from "./componets/recruiter/addjobreruiter";
import ViewJobRecruter from "./componets/recruiter/viewjobrecruiter";
import ChatRecruter from "./componets/recruiter/chatrecruiter";
import AboutRecruter from "./componets/recruiter/aboutrecruter";
import ProfileRecruter from "./componets/recruiter/profilerecruter";
import RegisterURM from "./componets/URM Candidate/register";
import Candidate from "./componets/URM Candidate/candidate";
import CandidateProfile from "./componets/URM Candidate/candidateprofile";
import Applications from "./componets/URM Candidate/applications";
import AppliedJobs from "./componets/URM Candidate/appliesjobs";
import Meetings from "./componets/URM Candidate/meeting";
import Feedback from "./componets/URM Candidate/feedback";
import SavedJobs from "./componets/URM Candidate/savedjobs";
import Admin from "./componets/admin/admin";
import AdminReports from "./componets/admin/adminreports";
import RegisterAcademic from "./componets/academic/register";
import ProfileManagement from "./componets/academic/profilemanagement";
import HomeAcademic from "./componets/academic/homeacademic";
import JobPosting from "./componets/academic/jobposting";
import ProfileManagementEdit from "./componets/academic/profilemanagementedit";
import AddJobPosting from "./componets/academic/addjobposting";
import Faculty from "./componets/academic/faculty";
import URMCandidate from "./componets/academic/urmcandidate";
import Noofjobs from "./componets/academic/noofjobs";
import FeedbackURM from "./componets/academic/feedbackurm";
import MessageURM from "./componets/academic/messageurm";
import "./App.css"
import store from "./store";
import ProtectedRoute from "./guard";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/forgotpassword" exact element={<ForgotPassword />} />
              <Route path="/registermain" exact element={<Registermain />} />
              <Route path="/registerdei" exact element={<RegisterDei />} />
              {/* <Route path="/dashboarddei" element={<DashboardDei />} /> */}
              <Route element={<ProtectedRoute allowedRoles={1} />}>
                  <Route path="/dashboarddei" element={<DashboardDei />} />
              </Route>
              <Route path="/addjob" exact element={<AddNewJob />} />
              <Route path="/viewjobapplicants" exact element={<ViewJonAppliciants />} />
              <Route path="/viewreports" exact element={<Reports />} />
              <Route path="/viewcomplaints" exact element={<Complaints />} />
              <Route path="/registerRecruiter" exact element={<RegisterRecruiter />} />
              <Route path="/homeRecrutier" exact element={<HomeRecrutier />} />
              <Route path="/AddJobRecruter" exact element={<AddJobRecruter />} />
              <Route path="/ViewJobRecruter" exact element={<ViewJobRecruter />} />
              <Route path="/ChatRecruter" exact element={<ChatRecruter />} />
              <Route path="/AboutRecruter" exact element={<AboutRecruter />} />
              <Route path="/ProfileRecruter" exact element={<ProfileRecruter />} />
              <Route path="/RegisterURM" exact element={<RegisterURM />} />
              <Route path="/Candidate" exact element={<Candidate />} />
              <Route path="/CandidateProfile" exact element={<CandidateProfile />} />
              <Route path="/Applications" exact element={<Applications />} />
              <Route path="/AppliedJobs" exact element={<AppliedJobs />} />
              <Route path="/Meetings" exact element={<Meetings />} />
              <Route path="/Feedback" exact element={<Feedback />} />
              <Route path="/SavedJobs" exact element={<SavedJobs />} />
              <Route path="/Admin" exact element={<Admin />} />
              <Route path="/AdminReports" exact element={<AdminReports />} />
              <Route path="/RegisterAcademic" exact element={<RegisterAcademic />} />
              <Route path="/ProfileManagement" exact element={<ProfileManagement />} />
              <Route path="/HomeAcademic" exact element={<HomeAcademic />} />
              <Route path="/JobPosting" exact element={<JobPosting />} />
              <Route path="/ProfileManagementEdit" exact element={<ProfileManagementEdit />} />
              <Route path="/AddJobPosting" exact element={<AddJobPosting />} />
              <Route path="/Faculty" exact element={<Faculty />} />
              <Route path="/URMCandidate" exact element={<URMCandidate />} />
              <Route path="/Noofjobs" exact element={<Noofjobs />} />
              <Route path="/FeedbackURM" exact element={<FeedbackURM />} />
              <Route path="/MessageURM" exact element={<MessageURM />} />
            </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
