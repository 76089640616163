import React, { useState } from "react";
import styles from "../layout/home.module.css"
import Footer from "../layout/footer";
const Registermain = () => {
  return (
    <div id="home">
      <nav className= {styles.nav}>
        <span className= {styles.logo}>URM Application</span>
        <a href="/">Home</a>
      </nav>
      <div className= {styles.body_height}>
        <h1 className= {styles.center}>Registration Page</h1>
        <br />
        <br />
        <div className= {styles.container}>
          <h1>Not have an account? Register Here!</h1>
          <div className= {styles.choose_register}>
            <a href="registerdei">
              <input
                type="button"
                value="Register As DEI Officer"
                className= {styles.registerbutton}
              />
            </a>
            <br />
            <br />
            <a href="registerRecruiter">
              <input
                type="button"
                value="Register As Recruiter"
                className= {styles.registerbutton}
              />
            </a>
            <br />
            <br />
            <a href="Admin">
              <input
                type="button"
                value="Register As Admin"
                className= {styles.registerbutton}
              />
            </a>
            <br />
            <br />
            <a href="RegisterURM">
              <input
                type="button"
                value="Register As URM Candidate"
                className= {styles.registerbutton}
              />
            </a>
            <br />
            <br />
            <a href="RegisterAcademic">
              <input
                type="button"
                value="Register As Academic Instituion"
                className= {styles.registerbutton}
              />
            </a>
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default Registermain;
