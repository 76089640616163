import React, { useState } from "react";
import styles from "./urm.module.css"
import HeaderURM from "./headerrurm";
import ChatURM from "./urmchat";
import Footer from "../../layout/footer";
const Meetings = () => {
    return (
        <div>
            <HeaderURM></HeaderURM>
            <div className={[styles.candidate_sign_in,styles.container,styles.body_height].join(' ') } >
                <form>
                    <h3>Candidate Meeting</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Location</th>
                                <th>Interviewer</th>
                                <th>Click here</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2023-08-15</td>
                                <td>10:00 AM</td>
                                <td>MicroSoft Teams</td>
                                <td>John Smith</td>
                                <td><input type="button" value="Join" /></td>

                            </tr>
                            <tr>
                                <td>2023-08-18</td>
                                <td>2:30 PM</td>
                                <td>yet to announce</td>
                                <td>Jane Doe</td>
                                <td><input type="button" value="Join" /></td>
                            </tr>
                            <tr>
                                <td>2023-08-22</td>
                                <td>9:00 AM</td>
                                <td>Online Zoom Meet</td>
                                <td>Michael Johnson</td>
                                <td><input type="button" value="Join Here" /></td>
                            </tr>
                            <tr>
                                <td>2023-08-15</td>
                                <td>10:00 AM</td>
                                <td>Microsoft teams</td>
                                <td>John Smith</td>
                                <td><input type="button" value="Join" /></td>
                            </tr>
                            <tr>
                                <td>2023-08-18</td>
                                <td>2:30 PM</td>
                                <td>Google Meet</td>
                                <td>Jane Doe</td>
                                <td><input type="button" value="Join" /></td>
                            </tr>
                            <tr>
                                <td>2023-08-22</td>
                                <td>9:00 AM</td>
                                <td>Yet to announce</td>
                                <td>Michael Johnson</td>
                                <td><input type="button" value="Join" /></td>

                            </tr>
                        </tbody>
                    </table>

                </form>
            </div>


            <div className={[styles.chat_container]} id="chatContainer">
        <div className={[styles.chat_header]} onclick="toggleChat()">Chat Box(1)</div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender] }>Recruiter:</span>
                <span className={[styles.message] }>Hello! I am reaching you about your application for Research assistant position</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message" />
            <button onclick="sendMessage()">Send</button>
        </div>
        </div>

            <br /><br /><br />









            <br /><br /><br /><br /><br /><br />
            <br /><br /><br />









<br /><br /><br /><br /><br /><br />
            <Footer></Footer>
        </div>
    );
};
export default Meetings;
