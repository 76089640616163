import React, { useState } from "react";
import styles from "./urm.module.css"
import HeaderURM from "./headerrurm";
import Footer from "../../layout/footer";
const SavedJobs = () => {
    return (
        <div>
            <HeaderURM></HeaderURM>
            <div className={[styles.candidate_sign_in, styles.container, styles.body_height].join(' ')} >
                <form>
                    <h3>Saved Jobs</h3>
                    <section>
                        <div className={[styles.container]}>
                            <p>
                                Institute Name: Univeristy of texas at Arlington
                            </p> <br />
                            <p>
                                Recruiter Name: Joseph
                            </p> <br />
                            <p>
                                Research Area: Artificial Intelligence
                            </p> <br />
                            <p>
                                Position Name: Research Assistant
                            </p> <br />
                            <p>
                                Qualification: Bachelors/Masters degree
                            </p> <br />
                            <p>
                                Job Description: Seeking a skilled and motivated Student to join our team and contribute to the Research development of Machine Learning Algorithms. The ideal candidate should have a strong background in Python,Data analysis,Math.
                            </p> <br />
                            <p>
                                Application Deadline: 08/08/2023
                            </p> <br />
                            <input type="button" value="Apply" /> <br></br>
                            <input type="button" value="Chat with the Recruiter" />
                        </div>
                    </section>
                    <section>

                        <div className={[styles.container]}>
                            <p>
                                Institute Name: University of Texas at Austin
                            </p> <br />
                            <p>
                                Recruiter Name: Sundar
                            </p> <br />
                            <p>
                                Research Area: Quantum physics
                            </p> <br />
                            <p>
                                Position Name: Research Assistant
                            </p> <br />
                            <p>
                                Qualification: PHd
                            </p> <br />
                            <p>
                                Job Description: Seeking a skilled and motivated Students join our team and contribute to the Research in Quantum Physics. The ideal candidate should have a strong background in Experimental Pyshics, Math.
                            </p> <br />
                            <p>
                                Application Deadline: 09/05/2023
                            </p> <br />
                            <input type="button" value="Apply" /><br></br>
                            <input type="button" value="Chat with the Recruiter" />
                        </div>
                    </section>
                </form>
            </div>
            <div className={[styles.chat_container]} id="chatContainer">
        <div className={[styles.chat_header]} onclick="toggleChat()">Chat Box(1)</div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender] }>Recruiter:</span>
                <span className={[styles.message] }>Hello! I am reaching you about your application for Research assistant position</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message" />
            <button onclick="sendMessage()">Send</button>
        </div>
        </div>
            <Footer></Footer>
        </div>
    );
};
export default SavedJobs;
