import React, { useState,useEffect } from "react";
import styles from "./urm.module.css"
import HeaderURM from "./headerrurm";
import Footer from "../../layout/footer";
import axios from "axios";
import { getUserDataFromLocalStorage } from '../../localStorageUtils';
const Applications = () => {
  const [jobs, setJobs] = useState([]);
  const userInfo = getUserDataFromLocalStorage();
  useEffect(() => {
    if (userInfo && userInfo.user_id) { 
        axios.get(`/getjobs.php`)
        .then((response) => {
            if(response.data){
              setJobs(response.data);
            }
        })
        .catch((error) => {
            console.error('Error fetching user data:', error);
        });
    }
}, []);
const handleClick = async (id) => {
  const valuesWithDefaultRole = {
    candidateid: userInfo.user_id,
    jobid: id
  };
  try {
    const response = await axios.post('/applyjob.php', valuesWithDefaultRole);
    if (response.data.success) {
      alert('Job Applied Successfully.')
      setJobs((prevJobs) =>
        prevJobs.map((job) =>
          job.jobid === id ? { ...job, hidden: true } : job
        )
      );
    }
  } catch (error) {
    alert('An error occurred:', error);
  }
};
const applyJob = async (id) => {
  console.log(id)
  const valuesWithDefaultRole = {
    candidateid : userInfo.user_id,
    jobid : id,
  };
  console.log(valuesWithDefaultRole)
  try {
    const response = await axios.post('/applyjob.php', valuesWithDefaultRole);
    
    console.log(response)
    if (response.data) {
    
      // switch(parseInt(role)){
      //   case RoleConstant.DEI:
      //     navigate("/DashboardDei");
      //     break;
      //   case RoleConstant.Admin:
      //     navigate("/AdminReports");
      //     break;
      //   case RoleConstant.Candidate:
      //     navigate("/Candidate");
      //     break;
      //   case RoleConstant.Recruiter:
      //     navigate("/HomeRecrutier");
      //     break;
      //   case RoleConstant.Academic:
      //     navigate("/HomeAcademic");
      //     break;
      // }
    } else {
      alert("Incorrect username or password.");
    }
  } catch (error) {
    alert('An error occurred:', error);
  }
}
  return (
    <div id="home">
      <HeaderURM></HeaderURM>
      <h3>List Of Jobs</h3>

      <form className={[styles.seach]}>
        <label htmlFor="search">Search:</label>
        <input type="search" id="search" name="search" placeholder="Enter Position" />
        <input type="search" id="search" name="search" placeholder="Enter Research" />
        <input type="search" id="search" name="search" placeholder="Enter Experience" />
        <input type="search" id="search" name="search" placeholder="Enter Institution Name" />
        <input type="search" id="search" name="search" placeholder="Enter Location" />
        <input type="submit" value="Search" />
      </form>
      {jobs === null ? (
        <p>Loading...</p>
      ) : jobs.length === 0 ? (
        <p>No jobs found</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Location</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.jobid}>
                <td>{job.jobid}</td>
                <td>{job.title}</td>
                <td>{job.description}</td>
                <td>{job.location}</td>
                <td>{job.datecreated}</td>
                <td>
                <td>
                  {!job.hidden && (
                    <button onClick={() => handleClick(job.jobid)}>
                      Apply
                    </button>
                  )}
                </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* <table>
        <tr>
          <th>Institution Name</th>
          <th>Recruiter Name</th>
          <th>Research Area</th>
          <th>Position Name</th>
          <th>Application Deadline</th>
          <th>Skills Required</th>
          <th>Experience/qualification Required</th>
          <th>Contact</th>
          <th>Apply</th>
          <th>Save the job</th>
        </tr>

        <tr>
          <td>Univeristy of texas at Arlington</td>
          <td>Joseph</td>
          <td>Artificial Intelligence</td>
          <td>Research Assistant</td>
          <td>08/02/2023</td>
          <td> python,knowledge in Maths </td>
          <td>Bachelors/Masters Degree</td>

          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>


        <tr>
          <td>Univeristy of texas at Arlington</td>
          <td>John</td>
          <td>Data Science</td>
          <td>Data Analyst</td>
          <td>08/08/2023</td>
          <td>Knowledge in Big Data</td>
          <td>Masters Degree</td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>

        <tr>
          <td>University of Texas at Austin</td>
          <td>Sundar</td>
          <td>Quantum physics</td>
          <td>Research Assistant</td>
          <td>09/05/2023</td>
          <td>Knowledge in quantum physics</td>
          <td> PHd</td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>

        <tr>
          <td>University of Texas at Austin</td>
          <td>Michael</td>
          <td>Electrical Engineering</td>
          <td>Teaching Assistant</td>
          <td>08/21/2023</td>
          <td>Knowledge in CAD</td>
          <td> Masters Degree/ 2Years industry experiance </td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>


        <tr>
          <td>University of Texas at Huston</td>
          <td>Amy</td>
          <td>Atomic Energy</td>
          <td>Professor</td>
          <td>09/06/2023</td>
          <td>Knowledge in Chemistry,Physics</td>
          <td>Doctrate </td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>

        <tr>
          <td>University of Texas at Arlington</td>
          <td>David</td>
          <td>Biology</td>
          <td>Professor</td>
          <td>10/01/2023</td>
          <td>Knowledge in neurobiology</td>
          <td> Doctrate/PHd </td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>

        <tr>
          <td>University of Texas at San antonio</td>
          <td>Olivia</td>
          <td>Computer Science</td>
          <td>Teaching Assistant</td>
          <td>07/24/2023</td>
          <td>knowledge in Algorithms</td>
          <td>Masters Degree</td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>

        <tr>
          <td>University of Texas at San antonio</td>
          <td>Christopher</td>
          <td>Robotics</td>
          <td>Research Assistant</td>
          <td>08/09/2023</td>
          <td>Knowledge in Programming and robotics</td>
          <td> PHd </td>
          <td><input type="button" value="Chat with the Recruiter" /></td>
          <td><input type="button" value="Apply" /></td>
          <td><input type="button" value="Save it" /></td>
        </tr>





      </table> */}


      <div className={[styles.chat_container]} id="chatContainer">
        <div className={[styles.chat_header]} onClick="toggleChat()">Chat Box(1)</div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender] }>Recruiter:</span>
                <span className={[styles.message] }>Hello! I am reaching you about your application for Research assistant position</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message" />
            <button onClick="sendMessage()">Send</button>
        </div>
        </div>

      <br /><br /><br />









      <br /><br /><br /><br /><br /><br />
      <Footer></Footer>
    </div>
  );
};
export default Applications;
