import React, { useState } from "react";
import styles from "./urm.module.css"
import HeaderURM from "./headerrurm";
import Footer from "../../layout/footer";
const Feedback = () => {
    return (
        <div id="home">
            <HeaderURM></HeaderURM>
            <div className={[styles.candidate_sign_in, styles.container, styles.body_height].join(' ')} >
                <form>
                    <h3>Feedback</h3>
                    <section>
                        <div className={[styles.container]}>
                            <p>
                                I was highly impressed with the candidate's deep knowledge and expertise in their research area. They showcased a strong understanding of the theoretical concepts and demonstrated excellent analytical skills during the interview. Their ability to articulate complex ideas in a clear and concise manner was commendable. Additionally, the candidate displayed a genuine enthusiasm for collaborative research and showed great potential for making significant contributions to our institution.
                            </p>
                            <p>
                                By Donald
                            </p>


                            <div className={[styles.rating]}>
                                <span className={[styles.thumbs_up]}>&#128077;</span>
                                <span className={[styles.thumbs_down]}>&#128078;</span>
                                <div className={[styles.star_rating]} class="star-rating">
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                </div>
                                <input type="button" value="Reply to this Feedback" />
                            </div>

                        </div>
                    </section>




                    <section>
                        <div className={[styles.container]}>
                            <p>
                                The candidate's academic qualifications and research experience were outstanding. They presented a well-structured and comprehensive research plan, showcasing innovative ideas and a clear research direction. Their ability to think critically and creatively in response to challenging questions was remarkable. Furthermore, the candidate displayed excellent interpersonal skills and effectively communicated their research to both technical and non-technical audiences. Their passion for teaching and mentorship was evident, making them a promising fit for our academic community.
                            </p>
                            <p>
                                By Donald
                            </p>


                            <div className={[styles.rating]}>
                                <span className={[styles.thumbs_up]}>&#128077;</span>
                                <span className={[styles.thumbs_down]}>&#128078;</span>
                                <div className={[styles.star_rating]}>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                    <span className={[styles.star]}>&#9733;</span>
                                </div>
                                <input type="button" value="Reply to this Feedback" />
                            </div>

                        </div>
                    </section>





                </form>
            </div>
            <div className={[styles.chat_container]} id="chatContainer">
        <div className={[styles.chat_header]} onclick="toggleChat()">Chat Box(1)</div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender] }>Recruiter:</span>
                <span className={[styles.message] }>Hello! I am reaching you about your application for Research assistant position</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message" />
            <button onclick="sendMessage()">Send</button>
        </div>
    </div>
<Footer></Footer>
        </div>
    );
};
export default Feedback;
