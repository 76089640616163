import React, { useState, useEffect } from "react";
import post from "./images/post.png";
import profile from "./images/profile.png";
import styles from "./urm.module.css"
import HeaderURM from "./headerrurm";
import ChatURM from "./urmchat";
import Footer from "../../layout/footer";
import { useSelector } from 'react-redux';
import axios from "axios";
import { getUserDataFromLocalStorage } from '../../localStorageUtils';
const CandidateProfile = () => {
    const [userData, setUserData] = useState(null);
    const userInfo = getUserDataFromLocalStorage();
    useEffect(() => {
        if (userInfo && userInfo.user_id) { 
            axios.get(`/getuser.php?userid=${userInfo.user_id}`)
            .then((response) => {
              if(response.data){
                setUserData(response.data);
            }
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
        }
    }, []);
    return (
        <div id="home">
            <HeaderURM></HeaderURM>
            <a href="Applications" > <img src={post} width="50" /></a><br /><br />

            <div className={[styles.candidate_sign_in, styles.container].join(' ')} >
                {
                    userData ? <form>
                        <h3 className={[styles.center]}>Your Profile Data</h3>
                        <div className={[styles.image_center]}>
                            <img src={profile} height="200" /> </div> <br /><br /><br />
                        Candidate Name:  {userData.firstName}   {userData.lastName} <br /><br /><br />
                        Candidate Phone Number: {userData.phone}<br /><br /><br />
                        Candidate Email: {userData.email}<br /><br /><br />
                        Nationality: {userData.nationality}<br /><br /><br />
                        Ethnicity: {userData.ethinictiy}<br /><br /><br />
                        Education: {userData.education}<br /><br /><br />
                        Research Area: {userData.studyfield}<br /><br /><br />
                    </form> : ""
                }

            </div>

            <ChatURM></ChatURM>

            <br /><br /><br />


            <br /><br /><br /><br /><br /><br />
            <Footer></Footer>
        </div>
    );
};
export default CandidateProfile;
