import React, { useState, useEffect } from "react";

import HeaderURM from "./headerrurm";
import styles from "./urm.module.css"
import Footer from "../../layout/footer";
import axios from "axios";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { getUserDataFromLocalStorage } from '../../localStorageUtils';

import { Formik, Field, Form, ErrorMessage } from 'formik';
const Candidate = () => {
    const userInfo = getUserDataFromLocalStorage();
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if (userInfo && userInfo.user_id) { 
        axios.get(`/getuser.php?userid=${userInfo.user_id}`)
        .then((response) => {
            if(response.data){
                setUserData(response.data);
            }
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
    }, []);
    const initialValues = {
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        email: userData?.email || '',
        phone: userData?.phone || '',
        PASSWORD: userData?.PASSWORD || '',
        demography: userData?.demography || '',
        ethinictiy: userData?.ethinictiy || '',
        nationality: userData?.nationality || '',
        studyfield: userData?.studyfield || '',
        education: userData?.education || ''
      };
    const validateForm = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'First name is required';
        }

        if (!values.lastName) {
            errors.lastName = 'Last name is required';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        }

        if (!values.phone) {
            errors.phone = 'Phone is required';
        }

        if (!values.PASSWORD) {
            errors.PASSWORD = 'Password is required';
        }

        if (!values.demography) {
            errors.demography = 'Demography is required';
        }

        if (!values.ethinictiy) {
            errors.ethinictiy = 'Ethnicity is required';
        }

        if (!values.nationality) {
            errors.nationality = 'Nationality is required';
        }

        if (!values.studyfield) {
            errors.studyfield = 'Study field is required';
        }

        if (!values.education) {
            errors.education = 'Education is required';
        }

        return errors;
    };

    const handleSubmit = async (values) => {
        const valuesWithDefaultRole = {
            ...values,
            id: userInfo.user_id
        };
        try {
            const response1 = await axios.post('/updateuser.php', valuesWithDefaultRole)
            console.log(response1)
            if (response1.data.success) {
                alert("Details Updated.");
            } else {
                alert("An error occurred:");
            }
        } catch (error) {
            alert('An error occurred:', error);
        }
    };
    return (
        <div id="home">
            <HeaderURM></HeaderURM>
            <div className={[styles.candidate_sign_in, styles.container].join('')}>
                    <h1>Edit Profile Data</h1>
                    {userData && (
                    <Formik
                        initialValues={initialValues}
                        validate={validateForm}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit }) => (
                            <Form className={[styles.registration_form]}>
                                <div>
                                    <label htmlFor="firstName">First Name</label>
                                    <Field type="text" id="firstName" name="firstName" />
                                    <ErrorMessage name="firstName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field type="text" id="lastName" name="lastName" />
                                    <ErrorMessage name="lastName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="email">Email</label>
                                    <Field type="text" id="email" name="email" />
                                    <ErrorMessage name="email" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="phone">Phone</label>
                                    <Field type="text" id="phone" name="phone" />
                                    <ErrorMessage name="phone" component="div" className="error_message" />
                                </div>
                                <div>
                                    <label htmlFor="PASSWORD">Password</label>
                                    <Field type="PASSWORD" id="PASSWORD" name="PASSWORD" />
                                    <ErrorMessage name="PASSWORD" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="demography">Demography</label>
                                    <Field type="text" id="demography" name="demography" />
                                    <ErrorMessage name="demography" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="ethinictiy">Ethnicity</label>
                                    <Field type="text" id="ethinictiy" name="ethinictiy" />
                                    <ErrorMessage name="ethinictiy" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="nationality">Nationality</label>
                                    <Field type="text" id="nationality" name="nationality" />
                                    <ErrorMessage name="nationality" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="studyfield">Study Field</label>
                                    <Field type="text" id="studyfield" name="studyfield" />
                                    <ErrorMessage name="studyfield" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="education">Education</label>
                                    <Field type="text" id="education" name="education" />
                                    <ErrorMessage name="education" component="div" className="error_message" />
                                </div>
                                <br /><input type="submit" value="Update Details" />   <br />
                            </Form>
                        )}
                    </Formik> )}
            </div>
            <Footer></Footer>
        </div>
    );
};
export default Candidate;
