import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const JobPosting = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.home]}>
      <div className={[styles.home_section]}>
        <button className={[styles.home_section,styles.button].join(' ')}> 
          <a className={[styles.home_section_url]} href="AddJobPosting">NEW JOB POSTING</a></button>
        <div className={[styles.job_listing]}>
          <h2>Associate Professor</h2>
          <h3>Bachelor 12/22/2023 </h3>
          
          <h4>Job Description</h4>
          <p>Associate Professors are mid-level faculty members who have demonstrated excellence in teaching, research, and service. They typically hold a doctoral degree and are actively involved in research projects. Associate Professors may also take on administrative roles and mentor junior faculty.</p> 
          <button className={[styles.home_section,styles.button].join(' ')}><a className={[styles.home_section_url]} href="Noofjobs">View Applications</a></button>
         
        </div>
        <div className={[styles.job_listing]}>
          <h2>Professor</h2>
          <h3>Bachelor Phd 12/22/2023 </h3>
          
          <h4>Job Description</h4>
          <p>Professors are senior faculty members with extensive expertise in their field of study. They are responsible for teaching advanced courses, conducting research, publishing scholarly articles, and mentoring graduate students. Professors often hold leadership positions within their departments and contribute to the development of academic programs.</p> 
          <button className={[styles.home_section,styles.button].join(' ')}><a className={[styles.home_section_url]} href="Noofjobs">View Applications</a></button>
        </div>
        <button name=".home_section button"> See Job Posting </button>
    </div>
    <div className={[styles.home_section]}>
      <div className={[styles.login]}>
        <h2 align="center">Enter Faculty details</h2>
        <form>
        <input name="id" type="integer"  placeholder="Enter Faculty ID" required/>
        <input name="name" type="text"  placeholder="Enter your Name" required/>
        <input name="course" type="text"  placeholder="Enter your Course" required/>
        <input name="year" type="integer"  placeholder="Enter your year" required/>
        <input name="time" type="integer"  placeholder="Enter your time" required/>
        <input name="login" type="submit" value="SUBMIT"/>
        </form>    
      </div>
      <div className={[styles.home_section,styles.faculty_table].join(' ')}>
        <h1>Faculty Management</h1>
    
              <table>
              <tr>
                <th>Faculty ID</th>
                <th>Name</th>
                <th>course</th>
                <th>year</th>
                <th>time</th>
              </tr>
              <tr>
                <td>1</td>
                <td>John Doe</td>
                <td>Computer Science</td>
                <td>2022</td>
                <td>1:00pm</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jane Smith</td>
                <td>Mathematics</td>
                <td>2022</td>
                <td>1:00pm</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Michael Johnson</td>
                <td>Physics</td>
                <td>2022</td>
                <td>1:00pm</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Sarah Williams</td>
                <td>Chemistry</td>
                <td>2022</td>
                <td>1:00pm</td>
              </tr>
              </table>
        </div>
    </div>
    </div>
    <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default JobPosting;
