import React, { useState } from "react";
import styles from "../layout/home.module.css"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from "axios";
import { useNavigate } from "react-router-dom"
const ForgotPassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: ''
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    }
    return errors;
  };
  const handleSubmit = async (values) => {
    const valuesWithDefaultRole = {
      ...values
    };
    try {
      const response = await axios.post('/forgotpassword.php', valuesWithDefaultRole)
      console.log(response)
      if (response.data === "success") {
        alert("Password send to email successfully. Please login now.");
        navigate("/login")
      } else {
        alert("Incorrect username.");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
  return (
    <div id="home">
      <nav className= {styles.nav}>
        <span className= {styles.logo}>URM Application</span>
        <a href="/">Home</a>
      </nav>
      <div className= {styles.body_height}>
        <h1 className= {styles.center}>Password Reset</h1>
        <br />
        <br />
        <div className= {styles.container}>
        <Formik
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                  <h1>Forgot Password?</h1>
                <div>
                  <label htmlFor="email">Enter Your Email</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="error_message" />
                </div>
                <input type="submit" value="Reset" className= {styles.button}/>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
