import React from "react";
import styles from "./hr.module.css"
const FooterHR = (props) => {
  return (
    <footer  className= {styles.footer}>
      <div  className= {styles.container}>
        <p>&copy; URM Application 2023 | All rights reserved</p>
        <p>
          <a href="#contact_us">Contact Us</a>
        </p>
      </div>
    </footer>
  );
};
export default FooterHR;
