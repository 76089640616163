import React, { useState,useEffect } from "react";
import HeaderDei from "./deiheader";
import ChatDei from "./deichat";
import styles from "./dei.module.css"
import axios from "axios";
import { getUserDataFromLocalStorage } from '../../localStorageUtils';
const ViewJonAppliciants = () => {
    const [jobs, setJobs] = useState([]);
    const userInfo = getUserDataFromLocalStorage();
    useEffect(() => {
      if (userInfo && userInfo.user_id) { 
          axios.get(`/viewjobdei.php?user_id=${userInfo.user_id}`)
          .then((response) => {
              if(response.data){
                setJobs(response.data);
              }
          })
          .catch((error) => {
              console.error('Error fetching user data:', error);
          });
      }
  }, []);
  const handleClick = async (status,job) => {
    const valuesWithDefaultRole = {
      status: status,
      id: job.id,
      updatedby: userInfo.user_id,
      firstName: job.firstName,
      lastName: job.lastName,
      title: job.title,
      email: job.email,
    };
    try {
      const response = await axios.post('/updatejobstatus.php', valuesWithDefaultRole);
      const id= job.id;
      console.log(response)
      if (response.data.success) {
        alert("Job application status changed");
        setJobs((prevJobs) =>
          prevJobs.map((job) =>
            job.jobid === id ? { ...job, status: status } : job
          )
        );
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
    return (
        <div id="home">
            <HeaderDei></HeaderDei>
            <br />
            <br />
            <h1 className={[styles.center]}>Job Applicants</h1>
            <form className={[styles.container]}>
            {jobs === null ? (
        <p>Loading...</p>
      ) : jobs.length === 0 ? (
        <p>No jobs found</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Job Title</th>
              <th>Candidate Name</th>
              <th>Candidate Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job.jobid}>
                <td>{job.jobid}</td>
                <td>{job.title}</td>
                <td>{job.firstName} {job.lastName} </td>
                <td>{job.email}</td>
                {
                    job.status == "Pending" && (
                        <td>
                            <td>
                  {!job.hidden && (
                    <button style={{marginRight:'20px'}} onClick={() => handleClick('Accepted',job)}>
                      Accept
                    </button>
                  )}
                   {!job.hidden && (
                    <button onClick={() => handleClick('Rejected',job)}>
                      Reject
                    </button>
                  )}
                </td>
                        </td>
                    )
                }
                 {
                    job.status == "Accepted" && (
                        <td>
                            <td>
                  {!job.hidden && (
                    <button style={{marginRight:'20px'}} onClick={() => handleClick('Rejected',job)}>
                      Reject
                    </button>
                  )}
                </td>
                        </td>
                    )
                }
                 {
                    job.status == "Rejected" && (
                        <td>
                            <td>
                  {!job.hidden && (
                    <button style={{marginRight:'20px'}} onClick={() => handleClick('Accepted',job)}>
                      Accept
                    </button>
                  )}
                </td>
                        </td>
                    )
                }
                {/* <td>
                  {!job.hidden && (
                    <button style={{marginRight:'20px'}} onClick={() => handleClick('Accepted',job.jobid)}>
                      Accept
                    </button>
                  )}
                   {!job.hidden && (
                    <button onClick={() => handleClick('Rejected',job.jobid)}>
                      Reject
                    </button>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
            </form>
            <br /><br />
            <br /><br />
            <ChatDei></ChatDei>
        </div>
    );
};
export default ViewJonAppliciants;
