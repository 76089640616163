import React, { useState } from "react";
import styles from "../layout/home.module.css"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from "axios";
import { useNavigate } from "react-router-dom"
import Footer from "../layout/footer";
import { useDispatch } from 'react-redux';
import RoleConstant from "../Constant";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: '',
    password: ''
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };
  const handleSubmit = async (values) => {
    localStorage.removeItem('userData');
    const valuesWithDefaultRole = {
      ...values
    };
    try {
      const response = await axios.post('/login.php', valuesWithDefaultRole);
      if (response.data.user_id) {
        console.log(response.data)
        localStorage.setItem('userData', JSON.stringify(response.data));
        const { user_id, role } = response.data;
        switch(parseInt(role)){
          case RoleConstant.DEI:
            navigate("/DashboardDei");
            break;
          case RoleConstant.Admin:
            navigate("/AdminReports");
            break;
          case RoleConstant.Candidate:
            navigate("/Candidate");
            break;
          case RoleConstant.Recruiter:
            navigate("/HomeRecrutier");
            break;
          case RoleConstant.Academic:
            navigate("/HomeAcademic");
            break;
        }
      } else {
        alert("Incorrect username or password.");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
  return (
    <div id="home">
      <nav  className= {styles.nav}>
        <span className= {styles.logo}>URM Application</span>
        <a href="/">Home</a>
      </nav>
      <div className= {styles.body_height}>
        <h1 className= {styles.center}>Login Page</h1>
        <br />
        <br />
        <div className= {styles.container}>
          <h1>Welcome Back!</h1>
          <Formik
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                <div>
                  <label htmlFor="email">Enter Your Email</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="error_message" />
                </div>
                <div>
                  <label htmlFor="password">Enter Your Password</label>
                  <Field type="password" id="password" name="password" />
                  <ErrorMessage name="password" component="div" className="error_message" />
                </div>
                <button type="submit" className= {styles.button}>Submit</button>
                <div className="forgot-password"><a href="forgotpassword">Forgot Your Password? Click here to reset it</a></div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default Login;
