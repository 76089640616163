import React, { useState } from "react";
import styles from "./dei.module.css"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Footer from "../../layout/footer";
import RoleConstant from "../../Constant";
import axios from "axios";
import { useNavigate } from "react-router-dom"
const RegisterDei = () => {
  const navigate = useNavigate()
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    org: '',
    password: '',
    confirmpassword: '',
    role: 1
  };
  const validateForm = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'First Name is required';
    }

    if (!values.lastName) {
      errors.lastName = 'Last Name is required';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (!/^\d{10}$/i.test(values.phone)) {
      errors.phone = 'Phone number must be 10 digits';
    }

    if (!values.org) {
      errors.org = 'Organization is required';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };
  const handleSubmit = async (values) => {
    const valuesWithDefaultRole = {
      ...values,
      role: RoleConstant.DEI,
    };
    try {
      const response = await axios.post('/user.php', valuesWithDefaultRole);
      if (response.data.success) {
        alert("Account Created Successfully.Please login to access your account.");
        navigate("/login")
      } else {
        alert("An error occurred:");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
  return (
    <div id="home">
      <nav className={styles.nav}>
        <span className={styles.logo}>URM Application</span>
        <a href="/">Home</a>
      </nav>
      <div className={styles.body_height}>
        <h1 className={styles.center}>DEI Officer Registration Page</h1><br /><br />
        <div className={styles.container}>
          <h1>Please Enter all the below mentioned details</h1>
          <Formik
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <Field type="text" id="firstName" name="firstName" />
                  <ErrorMessage name="firstName" component="div" className="error_message" />
                </div>

                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <Field type="text" id="lastName" name="lastName" />
                  <ErrorMessage name="lastName" component="div" className="error_message" />
                </div>

                <div>
                  <label htmlFor="email">Email</label>
                  <Field type="email" id="email" name="email" />
                  <ErrorMessage name="email" component="div" className="error_message" />
                </div>

                <div>
                  <label htmlFor="phone">Phone</label>
                  <Field type="text" id="phone" name="phone" />
                  <ErrorMessage name="phone" component="div" className="error_message" />
                </div>

                <div>
                  <label htmlFor="org">Organization</label>
                  <Field type="text" id="org" name="org" />
                  <ErrorMessage name="org" component="div" className="error_message"/>
                </div>

                <div>
                  <label htmlFor="password">Password</label>
                  <Field type="password" id="password" name="password" />
                  <ErrorMessage name="password" component="div" className="error_message" />
                </div>

                <div>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <Field type="password" id="confirmPassword" name="confirmPassword" />
                  <ErrorMessage name="confirmPassword" component="div" className="error_message" />
                </div>
                <button type="submit">Submit</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default RegisterDei;
