import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const AddJobPosting = () => {
    return (
      <div>
      <HeaderAcademic></HeaderAcademic>
      <div className={[styles.profile_container]}>
<h2>NEW JOB POSTING</h2>
<form className={[styles.profile_container_form]}>
<input name="job_role" type="text" placeholder="Enter your job role" required=""/>
<input name="job_description" type="text" placeholder="Enter your job_description" required=""/>
<input name="roles_and_responsibilities" type="text" placeholder="Enter your job_description" required=""/>
<input name="deadline" type="date" placeholder="Enter your deadline to submit application" required=""/>
<button>POST</button>
</form>    
</div>
<FooterAcademic></FooterAcademic>
  </div>
    );
};
export default AddJobPosting;
