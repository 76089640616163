import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import styles from "./hr.module.css"
const HeaderRecruiter = props => {
  return (
    <nav className= {[styles.nav] }>
      <span className= {[styles.logo] }>Recruiter</span>
      <Link to="/homeRecrutier" className= {[styles.active] }><span> Home </span></Link>
      <Link to="/AboutRecruter"><span> About </span></Link>
      <Link to="/ProfileRecruter"><span> Profile </span></Link>
      <Link to="/ChatRecruter"><span> Chat </span></Link>
      <Link to="/login"><span> Logout </span></Link>
    </nav>

  );
};
export default HeaderRecruiter