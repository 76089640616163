import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderRecruiter from "./headerrecrutier";
import FooterHR from "./footerhr";
import styles from "./hr.module.css"
const ChatRecruter = () => {
    return (
        <div id="home">
            <HeaderRecruiter></HeaderRecruiter>
            <div className={[styles.chat_container1, styles.body_height, styles.change].join(' ')}>
                <div className={[styles.chat_messages]}>
                    <div className={[styles.message, styles.left].join(' ')}>
                        <span className={[styles.sender]}>John:</span>
                        <span className={[styles.content]}>Hey, how are you?</span>
                    </div>
                    <div className={[styles.message, styles.right].join(' ')}>
                        <span className={[styles.sender]}>Sarah:</span>
                        <span className={[styles.content]}>I'm good, thanks! How about you?</span>
                    </div>
                    <div className={[styles.message, styles.left].join(' ')}>
                        <span className={[styles.sender]}>John:</span>
                        <span className={[styles.content]}>I'm doing great!</span>
                    </div>


                    <div className={[styles.message, styles.right].join(' ')}>
                        <span className={[styles.sender]}>Sarah:</span>
                        <span className={[styles.content]}>Do you have experience in PHP?</span>
                    </div>
                    <div className={[styles.message, styles.left].join(' ')}>
                        <span className={[styles.sender]}>John:</span>
                        <span className={[styles.content]}>Yes</span>
                    </div>


                    <div className={[styles.message, styles.right].join(' ')}>
                        <span className={[styles.sender]}>Sarah:</span>
                        <span className={[styles.content]}>What is your years of experience?</span>
                    </div>
                    <div className={[styles.message, styles.left].join(' ')}>
                        <span className={[styles.sender]}>John:</span>
                        <span className={[styles.content]}>Around 2 years.</span>
                    </div>


                    <div className={[styles.message, styles.right].join(' ')}>
                        <span className={[styles.sender]}>Sarah:</span>
                        <span className={[styles.content]}>Do you like to join in Noval Company? </span>
                    </div>
                    <div className={[styles.message, styles.left].join(' ')}>
                        <span className={[styles.sender]}>John:</span>
                        <span className={[styles.content]}>Yes, sure.</span>
                    </div>





                </div>
                <br /><br /><br /><br />
                <div  className={[styles.chat_input]}>
                    <input type="text" placeholder="Type your message..." />
                    <button>Send</button>
                </div>
            </div>
            <div className={[styles.right]} id="chat_persons">
                <h1>Messages</h1>
                <br /><br /><br /><br />
                <p className={[styles.container]}>Chat With Joseph</p>
                <br /><br />
                <p className={[styles.container]}>Chat With Robert</p>
                <br /><br />
                <p className={[styles.container]}>Chat With Rabi</p>
                <br /><br />
                <p className={[styles.container]}>Chat With James</p>
                <br /><br />
            </div>
            <FooterHR></FooterHR>
        </div>
    );
};
export default ChatRecruter;
