import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderDei from "./deiheader";
import ChatDei from "./deichat";
import styles from "./dei.module.css"
const Reports = () => {
    return (
        <div id="home">
             <HeaderDei></HeaderDei>
            <br />
            <br />
            <h1 className= {[styles.center] }>Job Postings and URM Candidate Interests</h1>
            <form className= {[styles.container] }>
                <table>
                    <thead>
                        <tr>
                            <th>Time Period</th>
                            <th>Total Job Postings</th>
                            <th>Job Postings (DEI Criteria met)</th>
                            <th>URM Candidates Interested</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Oct 2022</td>
                            <td>22</td>
                            <td>15</td>
                            <td>40</td>
                        </tr>
                        <tr>
                            <td>Nov 2022</td>
                            <td>23</td>
                            <td>20</td>
                            <td>60</td>
                        </tr>
                        <tr>
                            <td>Dec 2022</td>
                            <td>25</td>
                            <td>25</td>
                            <td>70</td>
                        </tr>
                        <tr>
                            <td>Jan 2023</td>
                            <td>22</td>
                            <td>15</td>
                            <td>40</td>
                        </tr>
                        <tr>
                            <td>Feb 2023</td>
                            <td>23</td>
                            <td>20</td>
                            <td>60</td>
                        </tr>
                        <tr>
                            <td>Mar2023</td>
                            <td>25</td>
                            <td>25</td>
                            <td>70</td>
                        </tr>

                    </tbody>
                </table>
            </form>
            <br /><br />
            <br /><br />
        </div>
    );
};
export default Reports;
