import React from "react";
import styles from "./dei.module.css"
const ChatDei = props => {
    return (
        <div className={[styles.chat_container]} id="chatContainer">
            <div className={[styles.chat_header]}>Chat with Candidate (1) </div>
            <div className={[styles.chat_body]}>
                <div className={[styles.chat_message]}>
                    <span className={[styles.sender] }>DEI Officer:</span>
                    <span className={[styles.message] }>Hello! How can I assist you?</span>
                </div>
            </div>
            <div className={[styles.chat_input]}>
                <input type="text" placeholder="Type your message" />
                <button>Send</button>
            </div>
        </div>
    );
};
export default ChatDei