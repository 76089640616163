import React, { useState } from "react";
import styles from "./admin.module.css"
import profile from "./images/profile.png";
import Footer from "../../layout/footer";
const AdminReports = () => {
    return (
        <div id="home">
            <div className={[styles.side_nav]}>
                <span className={[styles.logo]} style={{ paddingTop: "20px", display: "inline-block" }}>Admin</span>
                <form id="nav_form" style={{ backgroundColor: "transparent" }}>
                    <a href="#" id="collapse"> <span>‹</span></a>
                    <a href="#file" id="first_side"><span className={[styles.nav_content]}>Files</span></a>
                    <a href="#application"><span className={[styles.nav_content]}>Application Overview</span></a>
                    <a href="#total_post_per_job"><span className={[styles.nav_content]}>Total Post Per Job</span></a>
                    <a href="#matching_candidate"><span className={[styles.nav_content]}>Matching Candidates</span></a>
                    <a href="#user_activity"><span className={[styles.nav_content]}>User Activities</span></a>
                    <a href="#activity"><span className={[styles.nav_content]}> Activities</span></a>
                    <a href="#progress"><span className={[styles.nav_content]}>Progress</span></a>
                    <a href="mailto:info@example.com"><span className={[styles.nav_content]}>Help</span></a>
                    <a href="#settings"><span className={[styles.nav_content]}>Settings</span></a>
                    <a href="#change_user"><span className={[styles.nav_content]}>Change User</span></a>
                    <a href="/"><span className={[styles.nav_content]}>Logout</span></a>
                </form>
            </div>
            <div className={[styles.nav]}>
                <span className={[styles.logo]}>Administrator</span>
                <form id="nav_form" style={{ backgroundColor: "transparent", maxWidth:"1000px", marginLeft:"730px", display: "inline-block", textAlign: "right" }}>
                    <a href="#home">Home</a>
                    <a href="#applications">Applications</a>
                    <a href="#administrators">Administrators</a>
                    <a href="#subscription">Subscriptions</a>
                    <a href="#profile">Profile</a>
                    <a href="#notification">Notifications</a>
                    <a href="#"><select><option value="date">By Date</option><option value="name">By Name</option><option value="size">By Size</option></select> Filter</a>
                    <a href="#account">Manage Account</a>
                </form>
            </div>
            <div className={[styles.body_height]}> <div className={[styles.top_height]}></div>
                <h1 className={[styles.center]}>Administration Dashboard</h1><br /><br />

                <h1 className={[styles.center]} id="home">Welcome, Admin!</h1><br />

                <div className={[styles.container]}>	As a recruitment admin, your role is crucial in managing and facilitating the entire hiring process within our organization.
                    The Recruitment Admin Dashboard provides you with a centralized platform to oversee job postings, review candidate applications,
                    schedule interviews, and track the progress of candidates throughout the recruitment journey.
                </div>
                <div className={[styles.container]}>	 With this dashboard, you can efficiently handle all aspects of the hiring process, from posting new job openings to onboarding
                    successful candidates. You'll have access to key statistics, candidate profiles, and upcoming tasks, enabling you to make well-informed
                    decisions and ensure a seamless recruitment experience for both candidates and hiring managers. The Recruitment Admin Dashboard empowers you to efficiently manage talent acquisition, optimize hiring processes, and contribute to
                    building a skilled and diverse workforce that aligns with our organization's goals and values.
                </div><div className={[styles.container, styles.center].join(' ')} id="file">

                    <h2>Download Files</h2>
                    <form><input type="button" value="Download New Applicant File" />   <input type="button" value="Download Accepted Applicant File" />    </form>
                    <form><input type="button" value="Download New Jobs Available File" />   <input type="button" value="Download Rejected Applicant File" /> </form>
                    <form><input type="button" value="Download Application Status File" />  <input type="button" value="Download Candidate Data File" />  </form>
                </div>
                <div className={[styles.container]} id="application">
                    <h3>Application Overview</h3>
                    The administrator can have a registrant page where the/she can
                    manage the website's content, functionality, and user accounts.
                    They can oversee user registrations, approve new profiles, and
                    manage user data. They can also monitor user activity, respond
                    to user inquiries, and troubleshoot any issues that may arise.
                    The administrator dashboard can show information such as the
                    number of registered users, the number of job postings, and the
                    status of any reported issues or errors. They can also manage
                    user accounts,
                    review and approve new profiles, and monitor user activity.
                    Additionally, they can access any messages or chat requests
                    from users and respond to inquiries as needed.
                </div>

                <div className={[styles.container]}>
                    "Total Postings per Job" is a section in the Recruitment Admin Dashboard that presents a concise summary of job postings for various roles within the organization. It showcases the number of open positions for each job title, allowing the admin to quickly assess the current recruitment needs. This valuable insight enables efficient allocation of resources and targeted recruitment efforts to fill positions effectively and expediently.
                </div>
                <div className={[styles.container]} id="total_post_per_job">
                    <h2>Total Postings per Job</h2>
                    <table>
                        <tr>
                            <th>Job Title</th>
                            <th>Total Postings</th>
                        </tr>
                        <tr>
                            <td>Software Engineer</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>Project Manager</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>Marketing Specialist</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>Accountant</td>
                            <td>5</td>
                        </tr>
                    </table>
                </div>
                <div className={[styles.container]}>
                    "Matching Candidates Information" is a section in the Recruitment Admin Dashboard that displays relevant details about potential candidates who match specific client preferences. It presents candidate names, email addresses, and their status, allowing the admin to evaluate and manage suitable candidates for job placements. This feature streamlines the candidate selection process, enabling the admin to efficiently match candidates with the right job opportunities, ensuring better recruitment outcomes.
                </div>


                <div className={[styles.container]} id="matching_candidate">
                    <h2>Matching Candidates Information</h2>
                    <table>
                        <tr>
                            <th>Client Preference</th>
                            <th>Candidate Name</th>
                            <th>Candidate Email</th>
                            <th>Status</th>
                        </tr>
                        <tr>
                            <td>Preferred</td>
                            <td>John Doe</td>
                            <td>john.doe@example.com</td>
                            <td><form>
                                <select>
                                    <option >Not Selected</option>
                                    <option >Selected</option>
                                    <option >Pending</option>
                                </select></form>
                            </td>
                        </tr>
                        <tr>
                            <td>Not Preferred</td>
                            <td>Jane Smith</td>
                            <td>jane.smith@example.com</td>
                            <td><form>
                                <select>
                                    <option >Not Selected</option>
                                    <option >Selected</option>
                                    <option >Pending</option>
                                </select></form>
                            </td>
                        </tr>
                        <tr>
                            <td>Preferred</td>
                            <td>Michael Johnson</td>
                            <td>michael.johnson@example.com</td>
                            <td><form>
                                <select>
                                    <option >Pending</option>
                                    <option >Selected</option>
                                    <option >Not Selected</option>
                                </select></form>
                            </td>
                        </tr>
                        <tr>
                            <td>Preferred</td>
                            <td>Susan Lee</td>
                            <td>susan.lee@example.com</td>
                            <td><form>
                                <select>
                                    <option >Selected</option>
                                    <option >Not Selected</option>
                                    <option >Pending</option>
                                </select></form>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className={[styles.container]}>
                    "User Activity Details" is a section in the Recruitment Admin Dashboard that provides a comprehensive overview of user interactions within the platform. It includes user names, email addresses, and specific activities performed, such as error messages received, comments posted, files uploaded, or password changes. This valuable data empowers the admin to monitor user engagement, troubleshoot issues, and ensure a seamless user experience throughout the recruitment process.
                </div>
                <div className={[styles.container]} id="user_activity">

                    <h2>User Activity Details</h2>
                    <table>
                        <tr>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Activity</th>
                            <th>Troubleshoot</th>
                        </tr>
                        <tr>
                            <td>John Doe</td>
                            <td>john.doe@example.com</td>
                            <td>Got An Error Message</td>
                            <td><form><button className="btn-troubleshoot">Troubleshoot</button></form></td>
                        </tr>
                        <tr>
                            <td>Jane Smith</td>
                            <td>jane.smith@example.com</td>
                            <td>Posted a comment 1 day ago</td>
                            <td><form><button className="btn-troubleshoot">Troubleshoot</button></form></td>
                        </tr>
                        <tr>
                            <td>Michael Johnson</td>
                            <td>michael.johnson@example.com</td>
                            <td>Uploaded a file 3 hours ago</td>
                            <td><form><button className="btn-troubleshoot">Troubleshoot</button></form></td>
                        </tr>
                        <tr>
                            <td>Susan Lee</td>
                            <td>susan.lee@example.com</td>
                            <td>Changed password 5 days ago</td>
                            <td><form><button className="btn-troubleshoot">Troubleshoot</button></form></td>
                        </tr>
                    </table>


                </div>
                <div className={[styles.container]}>
                    "Activity Details" showcases essential metrics like the "Total Emails Received" and "Total Spams Blocked" in the Recruitment Admin Dashboard. This concise display allows the admin to track email flow and spam management efficiently, ensuring smooth communication and a secure inbox for all recruitment-related activities.
                </div>

                <div className={[styles.container]}>
                    <div className={[styles.container_box]} id="activity">
                        <div className={[styles.box_content]}>
                            98 SPAMS<br /> BLOCKED <br />
                        </div>
                    </div>

                    <div className={[styles.container_box]}  >
                        <div className={[styles.box_content]} >
                            450 <br />EMAILS <br />
                        </div>
                    </div>
                </div>




                <br /><br /><br />
                <div className={[styles.container]}>
                    "Progress Details" in the Recruitment Admin Dashboard visualize key metrics in colored bars for institutions and recruiters. It efficiently displays the total positions, candidates applied, and application acceptance status, enabling quick insights into recruitment progress and performance at a glance.
                </div>
                <div className={[styles.container, styles.center].join(' ')} id="progress">
                    <h3>Left Side Chart</h3>
                    <p >Total positions in XYZ institution (Green Bar).</p>
                    <p >Total positions in ABC institution (Yellow Bar).</p>


                    <h3>Right Side Chart</h3>
                    <p >Total candidates applied in XYZ institution (Green Bar).</p>
                    <p >Total candidates applied in in ABC institution (Yellow Bar).</p>


                    <div className={[styles.chart_container]} id="same_line">
                        <div className={[styles.bar]}>
                            <div className={[styles.bar_item, styles.bar_accept].join(' ')}>50%</div>
                            <div className={[styles.bar_item, styles.bar_pending].join(' ')}>32%</div>
                        </div>
                    </div>


                    <div className={[styles.chart_container]} id="same_line1">
                        <div className={[styles.bar]}>
                            <div className={[styles.bar_item, styles.bar_accept].join(' ')}>56%</div>
                            <div className={[styles.bar_item, styles.bar_pending].join(' ')} >22%</div>
                        </div>
                    </div>
                </div>
                <div className={[styles.container, styles.center].join(' ')} id="progress">
                    <h3>Left Side Chart</h3>
                    <p className={[styles.center]}>Total applications accepted by Recruiter Josua (Green Bar).</p>
                    <p className={[styles.center]}>Total applications pending by Recruiter Josua (Yellow Bar).</p>
                    <p className={[styles.center]}>Total applications rejected by Recruiter  Josua (Red Bar).</p>
                    <h3>Right Side Chart</h3>
                    <p className={[styles.center]}>Total applications accepted by DEI Officer Josua (Green Bar).</p>
                    <p className={[styles.center]}>Total applications pending by DEI Officer Josua (Yellow Bar).</p>
                    <p className={[styles.center]}>Total applications rejected by DEI Officer Josua (Red Bar).</p>

                    <div className={[styles.chart_container]} id="same_line">
                        <div className={[styles.bar]}>
                            <div className={[styles.bar_item, styles.bar_accept].join(' ')}>50%</div>
                            <div className={[styles.bar_item, styles.bar_pending].join(' ')} >20%</div>
                            <div className={[styles.bar_item, styles.bar_reject].join(' ')} >20%</div>
                        </div>
                    </div>




                    <div className={[styles.chart_container]} id="same_line1">
                        <div className={[styles.bar]}>
                            <div className={[styles.bar_item, styles.bar_accept].join(' ')}>58%</div>
                            <div className={[styles.bar_item, styles.bar_pending].join(' ')} >19%</div>
                            <div className={[styles.bar_item, styles.bar_reject].join(' ')} >95%</div>
                        </div>
                    </div>


                </div>
                <div id="settings" className={[styles.container, styles.center].join(' ')}>
                    <h3 id="change_user">Settings and Change User Option</h3>
                    <div className={[styles.left_setting]}>
                        <section>
                            <form><input type="button" required="" value="Delete Account" /> <br /><br />
                                <select> <option value="allow">Allow Notifications</option> <option value="not allow">Don't Allow Notifications</option> </select> <br /><br />
                                <select> <option value="Change Language:">Change Language</option>   <option value="english">English</option> <option value="hindi">Hindi</option> </select>
                            </form>
                        </section>
                    </div>

                    <div className={[styles.right_setting]}>
                        <section>
                            <form className={[styles.registration_form]}>
                                <select> <option value="ram_reddy">Ram Reddy</option><option value="reshma">Reshma</option><option value="robert">Robert</option></select> <br /><br />
                                <input type="button" required="" value="Change user" /> <br /><br />
                                <input type="button" required="" value="Remove user" />
                            </form>
                        </section>
                    </div>


                </div>
                <div className={[styles.container, styles.center].join(' ')} id="admin">
                    <h3>Admin Details</h3>
                    <table>
                        <tbody><tr>
                            <th>Name</th>
                            <th>Course</th>
                            <th>Year</th>
                            <th>Time</th>
                        </tr>
                            <tr>
                                <td>John Doe</td>
                                <td>Computer Science</td>
                                <td>3rd Year</td>
                                <td>9:00 AM - 12:00 PM</td>
                            </tr>
                            <tr>
                                <td>Jane Smith</td>
                                <td>Business Administration</td>
                                <td>2nd Year</td>
                                <td>1:00 PM - 4:00 PM</td>
                            </tr>
                            <tr>
                                <td>Michael Johnson</td>
                                <td>Engineering</td>
                                <td>4th Year</td>
                                <td>10:00 AM - 1:00 PM</td>
                            </tr>
                            <tr>
                                <td>Sarah Williams</td>
                                <td>Psychology</td>
                                <td>1st Year</td>
                                <td>3:00 PM - 6:00 PM</td>
                            </tr>
                            <tr>
                                <td>Robert Brown</td>
                                <td>English Literature</td>
                                <td>3rd Year</td>
                                <td>2:00 PM - 5:00 PM</td>
                            </tr>
                        </tbody></table>

                </div>
                <div className={[styles.container]}>
                    "Application Details" in the Recruitment Admin Dashboard presents a summary of candidate applications, their job roles, and the current status. This section helps the admin review and manage candidate profiles, facilitating effective decision-making in the hiring process.
                </div>
                <div className={[styles.container, styles.center].join(' ')} id="applications">
                    <h3>Application Details</h3>
                    <table>
                        <tbody><tr>
                            <th>Application ID</th>
                            <th>Candidate Name</th>
                            <th>Job Role</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                            <tr>
                                <td>001</td>
                                <td>John Doe</td>
                                <td>Software Engineer</td>
                                <td>Pending</td>

                                <td><form><select><option value="Accept">Accept</option><option value="Reject">Reject</option></select></form></td>

                            </tr>
                            <tr>
                                <td>002</td>
                                <td>Jane Smith</td>
                                <td>Web Developer</td>
                                <td>New</td>
                                <td><form><select><option value="Accept">Accept</option><option value="Reject">Reject</option></select></form></td>
                            </tr>
                            <tr>
                                <td>003</td>
                                <td>Mike Johnson</td>
                                <td>Data Analyst</td>
                                <td>In Progress</td>
                                <td><form><select><option value="Accept">Accept</option><option value="Reject">Reject</option></select></form></td>
                            </tr>
                            <tr>
                                <td>004</td>
                                <td>Sarah Wilson</td>
                                <td>Marketing Specialist</td>
                                <td>Pending</td>
                                <td><form><select><option value="Accept">Accept</option><option value="Reject">Reject</option></select></form></td>
                            </tr>
                            <tr>
                                <td>005</td>
                                <td>David Brown</td>
                                <td>Human Resources Manager</td>
                                <td>Complete</td>
                                <td><form><select><option value="Accept">Accept</option><option value="Reject">Reject</option></select></form></td>
                            </tr>
                        </tbody></table>

                </div>
                <div className={[styles.container]}>
                    "User Registrations" in the Recruitment Admin Dashboard provides an overview of newly registered users, their roles, and approval status. It enables the admin to efficiently manage user onboarding and permissions within the recruitment platform.
                </div>
                <div className={[styles.container]} id="settings">
                    <h2>User Registrations</h2>
                    <table>
                        <tbody><tr>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Role</th>
                            <th>Approval Status</th>
                        </tr>
                            <tr>
                                <td>John Doe</td>
                                <td>john.doe@example.com</td>
                                <td>Candidate</td>
                                <td><form>
                                    <select>
                                        <option value="approve">Allow</option>
                                        <option value="reject">Remove</option>
                                        <option value="edit">Edit</option>
                                    </select></form>
                                </td>
                            </tr>
                            <tr>
                                <td>Jane Smith</td>
                                <td>jane.smith@example.com</td>
                                <td>Recruiter</td>
                                <td><form>
                                    <select>
                                        <option value="approve">Allow</option>
                                        <option value="reject">Remove</option>
                                        <option value="edit">Edit</option>
                                    </select></form>
                                </td>
                            </tr>
                            <tr>
                                <td>Michael Johnson</td>
                                <td>michael.johnson@example.com</td>
                                <td>DEI Officer</td>
                                <td><form>
                                    <select>
                                        <option value="approve">Allow</option>
                                        <option value="reject">Remove</option>
                                        <option value="edit">Edit</option>
                                    </select></form>
                                </td>
                            </tr>
                            <tr>
                                <td>Susan Lee</td>
                                <td>susan.lee@example.com</td>
                                <td>Recruiter</td>
                                <td><form>
                                    <select>
                                        <option value="approve">Allow</option>
                                        <option value="reject">Remove</option>
                                        <option value="edit">Edit</option>
                                    </select></form>
                                </td>
                            </tr>
                        </tbody></table>
                </div>
                <div className={[styles.container]}>
                    In the Recruitment Admin Dashboard, the color-coded bars represent the status of candidate applications. Green indicates the total number of applications that have been accepted, yellow denotes applications pending review, and red signifies the count of applications that have been rejected. This visual representation allows the admin to quickly assess the overall progress of the recruitment process and take necessary actions accordingly.
                </div>
                <div className={[styles.container, styles.center].join(' ')}>
                    <p>Green - Total Applications Accepted</p>
                    <p>Yellow - Total Applications Pending</p>
                    <p>Red - Total Applications Rejected</p>

                    <div className={[styles.chart_container]} id="same_line1">
                        <div className={[styles.bar]}>
                            <div className={[styles.bar_item, styles.bar_accept].join(' ')} >60%</div>
                            <div className={[styles.bar_item, styles.bar_pending].join(' ')}>30%</div>
                            <div className={[styles.bar_item, styles.bar_reject].join(' ')} >90%</div>
                        </div>
                        {/* <div id="profile" className={[styles.container, styles.center].join(' ')}>
                            <h3 className={[styles.center]}>Profile</h3>
                            <img src={profile} width="100" /><br /><br />
                            Admin Name: Ram <br /><br />
                            Email: ram123@gmail.com <br /><br />
                            Password: ramreddy123 <br /><br />
                        </div> */}
                    </div>



                    <div id="same_line1">
                        <div id="profile" className={[styles.container, styles.center].join(' ')}>
                            <h3 className={[styles.center]}>Profile</h3>
                            <img src={profile} width="100" /><br /><br />
                            Admin Name: Ram <br /><br />
                            Email: ram123@gmail.com <br /><br />
                            Password: ramreddy123 <br /><br />
                        </div>
                    </div>


                </div>
                <div className={[styles.container]}>
                    <div className={[styles.container_box]} id="subscription">
                        <div className={[styles.box_content]} >
                            Subscriptions: ACTIVE <br />
                            <form>
                                <input type="button" value="Licensing" /><br />
                                <input type="button" value="Remove" /><br />
                            </form>
                        </div>
                    </div>

                    <div className={[styles.container_box]} id="top">
                        <div className={[styles.box_content]}>
                            90 <br />Administrators <br />
                        </div>
                    </div>
                </div>
                <div id="account" className={[styles.container]}>
<h3 className={[styles.center]}>Manage Account</h3>
  <form className={[styles.registration_form]}>
	<input type="text" required="" value="Ram"/> 
	<input type="email" required="" value="ram123@gmail.com"/> <br/><br/><br/>
	<input type="password" required="" pattern="^(?=.*[A-Z])(?=.*\d).*$" value="ram123"/>
 	<input type="button" required="" value="Update Account"/> 
	<input type="button" required="" value="Delete Account"/> 
  </form>
</div>
<div className={[styles.container]}>
"New Jobs Available!" is a notification section in the Recruitment Admin Dashboard that informs the admin about recently posted job openings. It displays essential details such as the job title, application deadline, required skills, and experience, allowing the admin to promptly review and manage the influx of new job opportunities.
</div>
<div id="notification" className={[styles.container]}>
<h3 className={[styles.center]}>New Jobs Available!</h3>
<table>
<tbody><tr>
<th>Position Name</th>
<th>Application Deadline</th>
<th>Skills Required</th>
<th>Experience Required</th>
<th>Action</th>
</tr>
<tr>
  <td>Data Analyst</td>
<td>Knowledge in Big Data</td>
<td> 2 years </td>
  <td>25-08-2023</td>
  <td><form> <input type="button" value="Approve "/>  <input type="button" value="Reject "/> </form> </td>
</tr>
<tr>
  <td>Developer</td>
<td>Full Stack Developer</td>
<td> 2 years </td>
  <td>25-08-2023</td>
  <td><form> <input type="button" value="Approve "/>  <input type="button" value="Reject "/> </form> </td>
</tr>
<tr>
  <td>Data Analyst</td>
<td>Knowledge in Big Data</td>
<td> 2 years </td>
  <td>25-08-2023</td>
  <td><form> <input type="button" value="Approve "/>  <input type="button" value="Reject "/> </form> </td>
</tr>

</tbody></table>
</div>
<div className={[styles.chat_container]}  id="chatContainer">
        <div className={[styles.chat_header]}  onclick="toggleChat()">Chat with Candidate (1) </div>
        <div className={[styles.chat_body]}>
            <div className={[styles.chat_message]}>
                <span className={[styles.sender]}>Candidate:</span>
                <span className={[styles.message]}>Hi!</span>
            </div>
        </div>
        <div className={[styles.chat_input]}>
            <input type="text" placeholder="Type your message"/>
            <button onclick="sendMessage()">Send</button>
        </div>
    </div>

                <br /><br /><br /><br />






            </div>
            <Footer></Footer>
        </div>
    );
};
export default AdminReports;
