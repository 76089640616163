import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const Faculty = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.home_section,styles.faculty_table].join(' ')}>
    <h1 style={{textAlign:"center"}}>Faculty Management</h1>
          <table className={[styles.faculty_management]}>
          <tbody><tr>
            <th>Faculty ID</th>
            <th>Name</th>
            <th>course</th>
            <th>year</th>
            <th>time</th>
          </tr>
          <tr>
            <td>1</td>
            <td>John Doe</td>
            <td>Computer Science</td>
            <td>2022</td>
            <td>1:00pm</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jane Smith</td>
            <td>Mathematics</td>
            <td>2022</td>
            <td>1:00pm</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Michael Johnson</td>
            <td>Physics</td>
            <td>2022</td>
            <td>1:00pm</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Sarah Williams</td>
            <td>Chemistry</td>
            <td>2022</td>
            <td>1:00pm</td>
          </tr>
          </tbody></table>
    </div>
    <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default Faculty;
