import React, { useState } from "react";

import logo from "./images/Academia-Logo.jpg";
import HeaderAcademic from "./headeracademic";
import styles from "./academic.module.css"
const HomeAcademic = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <section id="home" className={[styles.home,styles.bg_light].join(' ')}>
    <div className={[styles.container] }>
      <div className={[styles.home_p] }>
        <h2 className={[styles.text_center].join(' ') }>Home</h2>
      </div>
      <div  className={[styles.row,styles.home_t,styles.custom_shadow,].join(' ')}>
        <div className={[styles.homef]}>
          An academic dashboard serves as a centralized platform that provides students, educators, and administrators with valuable insights into academic performance, progress, and various other relevant data. Developing a well-structured and user-friendly home for an academic dashboard is essential to ensure seamless access to critical information, improve decision-making, and enhance the overall learning experience. In this article, we will explore the key considerations and steps involved in creating an effective home for an academic dashboard.The first step in building a successful academic dashboard is understanding the needs of its users. Identify the primary stakeholders, including students, teachers, and administrators, and conduct user surveys or interviews to gather insights on what features and data are most valuable to them. Incorporate this feedback into the design process to ensure the final product meets their expectations.
          Dashboard Components and Metrics:
          Determine the essential components and metrics that will be displayed on the home screen. Common components include personalized course schedules, upcoming assignments, recent grades, attendance records, and notifications. Displaying these key metrics at a glance provides users with immediate visibility into their academic journey.
          Visual representation of data is crucial to conveying complex information concisely. Utilize charts, graphs, and progress bars to present academic performance trends and growth over time. Clear visualizations help users understand their strengths and areas for improvement intuitively.
          Every student's academic journey is unique, so providing options for personalization and customization is vital. Allow users to set their preferences for the dashboard layout, theme, and the type of data they want to see upfront. Implementing a user-friendly settings section ensures the dashboard adapts to individual preferences.
        </div>
      </div>
  </div>
  </section>
  <section id="homef" className={[styles.homef,styles.bg_light].join(' ')}>
  <div className={[styles.container] }>
    <div className={[styles.homef,styles.row_home].join(' ')}>
      <div className="row_home_column custom-shadow homef">
        <p className="row_title" style= {{fontSize:"25px"}}>Faculty</p>
      <div><p className="">Faculty members play a crucial role in the success of higher education institutions. Their expertise, dedication, and commitment to teaching, research, and mentorship are pivotal in shaping the academic experiences of students. To ensure faculty members can thrive and excel in their roles, institutions must provide a supportive environment, professional development opportunities, and recognition for their contributions. In this article, we will explore best practices for empowering faculty in higher education and enhancing their effectiveness.</p>
      </div>
      </div>
      <div className="row_home_column custom-shadow homef">
        <p className="row_title" style= {{fontSize:"25px"}}>Programs</p>
      <div ><p className="">Academic institutions often implement various programs to enhance the learning experience, support student development, and foster a vibrant campus community. These programs can range from academic support initiatives to extracurricular activities, research opportunities, and student services. To ensure the success of these programs, academic institutions must carefully plan, execute, and assess them. In this article, we will explore strategies for effective program implementation in academic institutions.</p>
      </div>
      </div>
      <div className="row_home_column custom-shadow homef">
        <p className="row_title" style= {{fontSize:"25px"}}>Job</p>
      <div><p className="">Our home page features a user-friendly job search portal that allows you to explore a wide range of job listings from various industries and locations. Filter your search based on specific criteria, such as job title, location, experience level, and company. Get instant access to the latest openings and apply directly to positions that match your skill set and career aspirations.We understand that job searching can be daunting, which is why we offer expert career guidance to support you throughout your journey.</p>
      </div>
      </div>
    </div>
  </div>
  </section>
  <section  className={[styles.About_us,styles.bg_light].join(' ') }>
    <div className={[styles.container,styles.custom_shadow].join(' ') }>
      <div className={[styles.about_row] }>
        <div className={[styles.about_title] }>
            <div className="About_p">
              <h2 className={[styles.text_center]}>ABOUT US</h2>
            </div>
            <div className={[styles.divide_column] }>
            <div className={[styles.about_column] }>
              <img src={logo} className="about_column about_image"/>
            </div>
          <div className={[styles.about_column] }><p>An academic institution, also known as an educational institution or school, is an organization or establishment that provides formal education and training to individuals at various levels, ranging from preschool to higher education and beyond. These institutions play a crucial role in shaping individuals' intellectual, social, and personal development, equipping them with the knowledge, skills, and values needed to succeed in their future endeavors. </p></div>
        </div>
      </div>
       
      </div>
    </div>
  </section>
        </div>
    );
};
export default HomeAcademic;
