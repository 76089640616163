import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import styles from "./urm.module.css"
const HeaderURM = props => {
  return (
    <nav className={[styles.nav] }>
      <span className={[styles.logo] }>URM Applicants</span>
      <Link to="/Candidate" className={[styles.active] }><span> Edit Profile </span></Link>
      <Link to="/CandidateProfile"><span> Profile </span></Link>
      <Link to="/Applications"><span> Job Applications</span></Link>
      <Link to="/AppliedJobs"><span> Applied Jobs </span></Link>
      <Link to="/Meetings"><span> Meeting</span></Link>
      <Link to="/Feedback"><span> Feedback </span></Link>
      <Link to="/SavedJobs"><span> Saved Jobs </span></Link>
      <Link to="/login"><span> Sign Out </span></Link>
    </nav>

  );
};
export default HeaderURM