import React, { useState } from "react";
import styles from "./academic.module.css"
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
const ProfileManagementEdit = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.profile_container]}>
                <h1>Edit Academic User Profile</h1>
                {/* <button className={[styles.profile_container_button]}><a className={[styles.home_section_url]} href="profile_management_edit.html">Edit</a></button> */}
                <div className={[styles.profile_info]}>
                    <h2>Personal Information</h2>
                    <label for="name">Name:</label>
          <input type="text" id="name" name="name" required/>
  
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" required/>
  
          <label for="phone">Phone:</label>
          <input type="tel" id="phone" name="phone" required/>
                </div>
                <div className={[styles.educational_background]}>
                    <h2>Educational Background</h2>
                    <label for="degree">Degree:</label>
          <input type="text" id="degree" name="degree" required/>
  
          <label for="university">University:</label>
          <input type="text" id="university" name="university" required/>
  
          <label for="year">Year:</label>
          <input type="number" id="year" name="year" required/>
                </div>
                <div className={[styles.educational_background]}>
                    <h2>Research Experience</h2>
                    <label for="position">Position:</label>
          <input type="text" id="position" name="position" required/>
  
          <label for="institution">Institution:</label>
          <input type="text" id="institution" name="institution" required/>
  
          <label for="duration">Duration:</label>
          <input type="text" id="duration" name="duration" required/>
  
          <label for="research-topic">Research Topic:</label>
          <input type="text" id="research-topic" name="research-topic" required/>
                </div>
                <div className={[styles.preferences]}>
                    <h2>Preferences</h2>
                    <label for="field-of-study">Preferred Field of Study:</label>
          <input type="text" id="field-of-study" name="field-of-study" required/>
  
          <label for="positions-offering">Positions Offering:</label>
          <input type="text" id="positions-offering" name="positions-offering" required/>
                </div>
                <input type="submit" value="Submit"/>
            </div>
            <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default ProfileManagementEdit;
