import React, { useState } from "react";
import Footer from "../../layout/footer";
import styles from "./admin.module.css"
import RoleConstant from "../../Constant";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from "axios";
import { useNavigate } from "react-router-dom"
const Admin = () => {
    const navigate = useNavigate()
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmpassword: ''
    };
    const validateForm = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'First Name is required';
        }

        if (!values.lastName) {
            errors.lastName = 'Last Name is required';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        } else if (values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters long';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        return errors;
    };
    const handleSubmit = async (values) => {
        const valuesWithDefaultRole = {
            ...values,
            role: RoleConstant.Admin,
        };
        try {
            const response = await axios.post('/user.php', valuesWithDefaultRole);
            if (response.data.success) {
              alert("Account Created Successfully");
              navigate("/AdminReports")
            } else {
              alert("An error occurred:");
            }
          } catch (error) {
            alert('An error occurred:', error);
          }
    };
    return (
        <div id="home">
            <nav className={[styles.nav]}>
                <span className={[styles.logo]}>Administrator</span>
                <a href="/">Home</a>
            </nav> <br></br>
            <div className={[styles.body_height]}>
                <h1 className={[styles.center]}>Admin Registration Page</h1><br /><br />
                <div className={[styles.container]}>
                    <h1>Please Enter all the below mentioned details</h1>
                    <Formik
                        initialValues={initialValues}
                        validate={validateForm}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit }) => (
                            <Form>
                                <div>
                                    <label htmlFor="firstName">First Name</label>
                                    <Field type="text" id="firstName" name="firstName" />
                                    <ErrorMessage name="firstName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field type="text" id="lastName" name="lastName" />
                                    <ErrorMessage name="lastName" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="email">Email</label>
                                    <Field type="email" id="email" name="email" />
                                    <ErrorMessage name="email" component="div" className="error_message" />
                                </div>
                                <div>
                                    <label htmlFor="password">Password</label>
                                    <Field type="password" id="password" name="password" />
                                    <ErrorMessage name="password" component="div" className="error_message" />
                                </div>

                                <div>
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <Field type="password" id="confirmPassword" name="confirmPassword" />
                                    <ErrorMessage name="confirmPassword" component="div" className="error_message" />
                                </div>
                                <button type="submit">Submit</button>
                            </Form>
                        )}
                    </Formik>
                    {/* <form className={[styles.registration_form]} action="AdminReports">
                        <input type="text" placeholder="AdminID" />
                        <input type="text" placeholder="FirstName" />
                        <input type="text" placeholder="LastName" />
                        <input type="email" placeholder="Email" />  <br /> <br /> <br />
                        <input type="password" placeholder="Create Password" />
                        <input type="password" placeholder="Confirm Password" />  <br /> <br /> <br /> <br /> <br /> <br />
                        <br />
                        <input type="submit" value="Create" />
                    </form> */}
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};
export default Admin;
