import React, { useState } from "react";
import HeaderDei from "./deiheader";
import styles from "./dei.module.css"
import ChatDei from "./deichat";
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Footer from "../../layout/footer";
import * as Yup from 'yup';
import { getUserDataFromLocalStorage } from '../../localStorageUtils';

const AddNewJob = () => {
  const userData = getUserDataFromLocalStorage();
  if (userData) {
    console.log('User Data Found:', userData);
    // Do something with userData
  } else {
    console.log('User Data Not Found');
  }
  const FormField = ({ label, name, type = 'text' }) => (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field type={type} id={name} name={name} />
      <ErrorMessage name={name} component="div" className="error_message" />
    </div>
  );
  
  const navigate = useNavigate()
  const initialValues = {
    title: '',
    description: '',
    location: ''
  };
  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
  });
  const handleSubmit = async (values) => {
    const valuesWithDefaultRole = {
      ...values,
      addedby: userData.user_id,
    };
    try {
      const response = await axios.post('/createjob.php', valuesWithDefaultRole);
      if (response.data.success) {
        alert("Job Created Successfully");
        navigate("/DashboardDei")
      } else {
        alert("An error occurred:");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };

  return (
    <div id="home">
      <HeaderDei></HeaderDei>
      <br />
      <br />
      <h1 className={[styles.center]}>Add Job Post</h1>
      <div className={[styles.container]}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormField label="Title" name="title" />
            <FormField label="Description" name="description" />
            <FormField label="Location" name="location" />
            <button type="submit" className={styles.button}>Submit</button>
          </Form>
        </Formik>
      </div>
      <br /><br />
      <br /><br />
      <ChatDei></ChatDei>
      <Footer></Footer>
    </div>
  );
};
export default AddNewJob;
