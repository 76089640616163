import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import styles from "./dei.module.css"

const HeaderDei = props => {
  return (
    <nav className= {styles.nav}>
      <span className= {styles.logo}>DEI Officer</span>
      <Link to="/" className= {styles.active}><span> Home </span></Link>
      <Link to="/dashboarddei"><span> Dashboard </span></Link>
      <Link to="/dashboarddei#goals"><span> Goals </span></Link>
      <Link to="/dashboarddei#initiatives"><span> Initiatives </span></Link>
      <Link to="/dashboarddei#candidate_pref"><span> Candidate Preferences </span></Link>
      <Link to="/login"><span> Logout </span></Link>
    </nav>

  );
};
export default HeaderDei