import React from "react";
import styles from "./academic.module.css"
const FooterAcademic = (props) => {
    return (
        <footer id="foot" className={[styles.foot, styles.bg_dark].join(' ')}>
            <div className={[styles.footer_top]}>
                <div className={[styles.footcontainer]}>
                    <div className={[styles.row]}>
                        <div className={[styles.col_lg_4,styles.footer_contact].join(' ')}>
                            <h3>Academic Institution</h3>
                            <p>
                                Arlington<br />
                                Texas<br /><br />
                                <strong>Phone:</strong> +91-9656214124<br />
                                <strong>Email:</strong> academic-institutions@gmail.com<br />
                            </p>
                        </div>
                        <div className={[styles.col_lg_4,styles.foot_links].join(' ')}>
                            <h4 className="text-info">Useful Links</h4>
                            <ul>
                                <li> <a href="HomeAcademic">Home</a></li>
                                <li> <a href="ProfileManagement">profile Management</a></li>
                                <li> <a href="AddJobPosting">Job posting</a></li>
                                <li> <a href="URMCandidate">URM Candidaate</a></li>
                            </ul>
                        </div>
                        <div className={[styles.col_lg_4,styles.foot_links].join(' ')}>
                            <h4 className="text-info">Our Services</h4>
                            <ul>
                                <li> <a href="AddJobPosting">Jobs</a></li>
                                <li> <a href="HomeAcademic">Reasearch and Development</a></li>
                                <li> <a href="Faculty">Faculty Management</a></li>
                                <li> <a href="FeedbackURM">Feedback</a></li>
                                <li> <a href="MessageURM">Messaging</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" d-md-flex py-4">
                <div className="mr-md-auto text-center text-white text-md-left">
                    <div className="copyright">
                        © Copyright. All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default FooterAcademic;
