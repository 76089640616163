import React, { useState } from "react";
import HeaderRecruiter from "./headerrecrutier";
import FooterHR from "./footerhr";
import styles from "./hr.module.css"
import * as Yup from 'yup';
import { getUserDataFromLocalStorage } from '../../localStorageUtils';
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from 'formik';
const AddJobRecruter = () => {
    const userData = getUserDataFromLocalStorage();
  if (userData) {
    console.log('User Data Found:', userData);
  } else {
    console.log('User Data Not Found');
  }
  const FormField = ({ label, name, type = 'text' }) => (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field type={type} id={name} name={name} />
      <ErrorMessage name={name} component="div" className="error_message" />
    </div>
  );
  
  const initialValues = {
    title: '',
    description: '',
    location: '',
  };
  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
  });
  const handleSubmit = async (values) => {
    const valuesWithDefaultRole = {
      ...values,
      addedby: userData.user_id,
    };
    try {
      const response = await axios.post('/createjob.php', valuesWithDefaultRole);
      if (response.data.success) {
        alert("Job Created Successfully");
      } else {
        alert("An error occurred:");
      }
    } catch (error) {
      alert('An error occurred:', error);
    }
  };
    return (
        <div id="home">
            <HeaderRecruiter></HeaderRecruiter>
            <br />
            <br />
            <h1 className= {[styles.center] }>Add Job Post</h1>
            <div className= {[styles.container] }>
            <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormField label="Job Title" name="title" />
            <FormField label="Job Role" name="jobrole" />
            <FormField label="Job Description" name="description" />
            <FormField label="Location" name="location" />
            <FormField label="Skills Required:" name="skills" />
            <FormField label="Last Application Date" name="applicationdate" />
            <button type="submit" className={styles.button}>Submit</button>
          </Form>
        </Formik>
                {/* <form>
                    <label for="title">Job Title</label>
                    <input type="text" id="title" name="title" required="" /><br /><br />

                    <label for="role">Job Role</label>
                    <input type="text" id="role" name="role" required="" /><br /><br />

                    <label for="description">Job Description</label>
                    <input type="text" id="description" name="description" required="" /><br /><br />

                    <label for="skills">Skills Required:</label>
                    <input type="text" id="skills" name="skills" required="" /><br /><br />

                    <label for="experience">Location:</label>
                    <input type="text" id="location" name="location" required="" /><br /><br />

                    <label for="deadline">Last Application Date:</label>
                    <input type="date" id="deadline" name="deadline" required="" /><br /><br />
                    <input type="submit" value="Submit" />
                </form> */}
            </div>
            <FooterHR></FooterHR>
        </div>
    );
};
export default AddJobRecruter;
