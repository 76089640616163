import React from "react";
import styles from "./home.module.css"
const Header = props => {
    return (
      <nav className= {styles.nav}>
      <span  className= {styles.logo}>URM Application</span>
      <a href="/"  className= {styles.active}>Home</a>
      <a href="#about_us">About Us</a>
      <a href="#services">Services</a>
      <a href="#login">Login/Register</a>
      <a href="#contact_us">Contact Us</a>
      <a href="https://na2veen37.wordpress.com/" target="_blank">Blog</a>
    </nav>
      );
};
export default Header