import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import styles from "./academic.module.css"
const HeaderAcademic = props => {
  return (
    <ul className={[styles.navbar]}>
      <label className={[styles.nav_title]}>ACADEMIC INSTITUTION</label>
      <li className={[styles.nav_item]}>
        <Link to="/" ><span> Log Out </span></Link>
      </li>
      <li className={[styles.nav_item]}>
        <Link to="/Faculty" ><span> Faculty Management </span></Link>
      </li>
      <li className={[styles.nav_item]}>
        <Link to="/URMCandidate" ><span> URM Candidate </span></Link>
      </li>
      <li className={[styles.nav_item]}>
        <Link to="/JobPosting" ><span> Job Posting </span></Link>
      </li>
      <li className={[styles.nav_item]}>
        <Link to="/ProfileManagement" ><span> Profile Management </span></Link>
      </li>
      <li className={[styles.nav_item]}>
        <Link to="/HomeAcademic" className={[styles.active]}><span> Home </span></Link>
      </li>

    </ul>

  );
};
export default HeaderAcademic