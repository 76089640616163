import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const MessageURM = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <h1 className={[styles.message_title]}>Academic Institution Messaging System</h1>
            <div className={[styles.messaging]}>
                <div className={[styles.messaging_container]}>
                    <div className={[styles.message]}>
                        <div className={[styles.name]}>John Doe</div>
                        <div className={[styles.timestamp]}>2023-07-21 10:30 AM</div>
                        <div className={[styles.content]}>
                            <p>Hello, I came across your profile and would like to discuss research collaboration opportunities.</p>
                        </div>
                    </div>
                    <div style={{textAlign:"right"}}>
                        <div className={[styles.receiver]}>
                            <div className={[styles.name]}>Jane Smith</div>
                            <div className={[styles.timestamp]}>2023-07-21 11:00 AM</div>
                            <div className={[styles.content]}>
                                <p>Thank you for reaching out! I am interested in learning more about potential collaborations.</p>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.message]}>
                        <div className={[styles.name]}>John Doe</div>
                        <div className={[styles.timestamp]}>2023-07-21 10:30 AM</div>
                        <div className={[styles.content]}>
                            <p>
                                Good to hear that!</p>
                        </div>
                    </div>
                    <div  style={{textAlign:"right"}}>
                        <div className={[styles.receiver]}>
                            <div className={[styles.name]}>Jane Smith</div>
                            <div className={[styles.timestamp]}>2023-07-21 11:00 AM</div>
                            <div className={[styles.content]}>
                                <p>Thank you </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[styles.message_box]}>
                    <div className={[styles.message_input]}>
                        <input type="text" id="message-input" placeholder="Type your message..." />
                        <button id="send-button">Send</button>
                    </div>
                </div>
            </div>
            <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default MessageURM;
