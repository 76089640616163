import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import HeaderRecruiter from "./headerrecrutier";
import FooterHR from "./footerhr";
import styles from "./hr.module.css"
const ProfileRecruter = () => {
    return (
        <div id="home">
            <HeaderRecruiter></HeaderRecruiter>
            <br />
            <br />

            <div className={[styles.body_content]}>
                <h3 className={[styles.center]}>Recruiter Profile</h3>
            </div>
            <div className={[styles.container]} >

                <form>

                    <label for="firstName">First Name:</label>
                    <input type="text" id="firstName" name="firstName" required /><br /><br />

                    <label for="lastName">Last Name:</label>
                    <input type="text" id="lastName" name="lastName" required /><br /><br />

                    <label for="phone">Phone Number:</label>
                    <input type="tel" id="phone" name="phone" required /><br /><br />

                    <label for="address">Address:</label>
                    <textarea id="address" name="address" required></textarea><br /><br />

                    <label for="dob">Date of Birth:</label>
                    <input type="date" id="dob" name="dob" required /><br /><br />

                    <label for="skills">Skills:</label>
                    <input type="text" id="skills" name="skills" required /><br /><br />

                    <label for="Linkedin">Linkedin:</label>
                    <input type="text" id="Linkedin" name="Linkedin" required /><br /><br />

                    <label for="Email">Email:</label>
                    <input type="email" id="Email" name="Email" required /><br /><br />

                    <label for="Education">Education:</label>
                    <input type="text" id="Education" name="Education" required /><br /><br />

                    <label for="working_experience">Working experience:</label>
                    <input type="text" id="working_experience" name="working_experience" required /><br /><br />




                    <input type="submit" value="Submit and Save Changes" />
                </form>


            </div>
            <FooterHR></FooterHR>


        </div>
    );
};
export default ProfileRecruter;
