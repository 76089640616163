// store.js

import { createStore } from 'redux';

const initialState = {
  user: {
    user_id: null,
    role: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: {
          user_id: action.payload.user_id,
          role: action.payload.role,
        },
      };
    case 'LOGOUT':
      return {
        ...state,
        user: {
          user_id: null,
          role: null,
        },
      };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
