// localStorageUtils.js
export const getUserDataFromLocalStorage = () => {
    const userDataJSON = localStorage.getItem('userData');
    if (userDataJSON) {
      return JSON.parse(userDataJSON);
    }
    return null;
  };
  
  export const clearUserDataFromLocalStorage = () => {
    localStorage.removeItem('userData');
  };