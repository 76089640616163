import React, { useState } from "react";
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
import styles from "./academic.module.css"
const FeedbackURM = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.profile_container]}>
                <h2>Feedback</h2>
                <form className={[styles.profile_container_form]}>
                    <input name="feedback" type="text" placeholder="Enter your feedback" required="" />
                    <input name="rating" type="text" placeholder="give your rating" required="" />
                    <button>POST</button>
                </form>
            </div>
            <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default FeedbackURM;
