import React, { useState } from "react";
import HeaderDei from "./deiheader";
import styles from "./dei.module.css"
import ChatDei from "./deichat";
import { HashLink as Link } from 'react-router-hash-link';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"
const DashboardDei = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    // Dispatch the logoutUser action to reset the state
    dispatch({ type: 'LOGOUT'})
  };
  return (
    <div id="home">
     <nav className= {styles.nav}>
      <span className= {styles.logo}>DEI Officer</span>
      <Link to="/" className= {styles.active}><span> Home </span></Link>
      <Link to="/dashboarddei#goals"><span> Goals </span></Link>
      <Link to="/dashboarddei#initiatives"><span> Initiatives </span></Link>
      <Link to="/dashboarddei#candidate_pref"><span> Candidate Preferences </span></Link>
      <Link to='/login' onClick={handleLogout}><span> Logout </span></Link>
    </nav>
      <br />
      <br />
      <h1 className= {styles.center}>DEI Officer Dashboard</h1>
      <br />
      <br />
      <div className= {[styles.container , styles.center].join(' ') }>
        <form>
          <a href="addjob">
            {" "}
            <input type="button" value="Add Job Postings" />{" "}
          </a>
        </form>
        <form>
          <a href="viewjobapplicants">
            {" "}
            <input type="button" value="View Job Applicants" />{" "}
          </a>
        </form>
        <form>
          {" "}
          <a href="viewcomplaints">
            <input type="button" value="View Complaints" />
          </a>{" "}
        </form>
        <form>
          {" "}
          <a href="viewreports">
            <input type="button" value="View Reports" />
          </a>{" "}
        </form>
      </div>
      <br></br>
      <br></br>
      <div className= {[styles.container] } id="goals">
        <h3 className= {styles.center}>Goals</h3>
        <ul>
          <li>Promoting diversity: We want to create a workforce that is diverse in all aspects, including the hiring, promotion, and
            retention of people from underrepresented groups.
            This entails fostering an inclusive workplace and implementing tactics to entice different talent.</li>
          <li>Ensuring equity: We seek to identify and rectify any injustices that may exist inside the company, such as differences in salary,
            chances for advancement, and resource access.
            We work hard to develop impartial rules and procedures that support equal opportunity and treatment for all workers.</li>
          <li>Creating an inclusive workplace: Fostering an inclusive culture that acknowledges and respects people's differences is our main goal when creating an
            inclusive workplace. Developing and putting into action activities and programs, like training sessions, cultural events, and
            employee resource groups, that advance knowledge about,
            education about, and understanding of diversity and inclusion are necessary to achieve this.</li>
          <li>Mitigating bias and discrimination: Educating the organization about discrimination and bias that isn't consciously being practiced. We strive to put into practice policies and practices
            that address and avoid prejudice and discrimination in contexts like employment, promotions, and everyday interactions.</li>
        </ul>
      </div>
      <br></br>
      <br></br>
      <div className= {[styles.container] } id="initiatives">
        <h3 className= {styles.center}>Initiatives</h3>
        <ul>
          <li>Training and education: Create and provide training courses and seminars that promote understanding of issues relating to diversity, equity, and inclusion. This may involve lectures on implicit prejudice, cultural sensitivity, inclusive
            leadership, and fostering inclusive workplaces. Employees at various organizational levels may receive training.</li>
          <li>Recruitment and Hiring Procedures: Work with the HR teams to examine and update the hiring and recruitment procedures to make sure they are inclusive and support diversity. This may entail putting in place techniques to draw people from various backgrounds, eliminating prejudice from job descriptions
            and interview procedures, and forming alliances with businesses that concentrate on developing diverse talent pipelines.</li>
          <li>Employee Resource Groups (ERGs): Support the formation and expansion of employee resource groups (also known as affinity groups). These organizations unite workers with similar traits or histories, such as women, people of color, LGBTQ+ persons, or people with disabilities.
            ERGs give the organization a place to network, encourage one another, and celebrate its many identities.</li>
          <li>Policy and Procedure Review: Examine current rules and procedures to spot any potential biases or barriers to inclusion and deal with them. Work on creating and implementing equitable hiring, promotion, pay, and workplace accommodation rules.
            Make sure that all staff are adequately informed on policies and procedures.</li>
          <li>Community Outreach and Partnerships: Collaborate with external organizations and community groups that focus on diversity and inclusion. Participate in events, conferences, and forums to share best practices and learn from others in the field.
            Seek opportunities to support and engage with diverse communities beyond the workplace.</li>
        </ul>
      </div>
      <br></br>
      <br></br>
      <div className= {[styles.container] } id="candidate_pref">
        <h3 className= {styles.center}>Candidate Preferences</h3>
        <ul>
          <li>Diversity-Friendly Communities: Locations with diverse populations and inclusive cultures that align with the organization's DEI objectives.</li>
          <li>Thriving Urban Areas: Urban or metropolitan regions with abundant job opportunities and industry hubs focused on promoting diversity, equity, and inclusion.</li>
          <li>Progressive Regions: Areas with forward-thinking approaches to diversity and social issues, fostering an environment conducive to DEI initiatives.</li>
          <li>Balanced Lifestyle: Locations that offer a positive work-life balance, along with access to cultural activities and recreational options.</li>
        </ul>
        <h3 className= {styles.center}>Location Preferences</h3>
        <ul>
          <li>URM candidates committed to promoting diversity and inclusion.</li>
          <li>Those with unique perspectives and experiences.</li>
          <li>Cultural competence and effective communication skills.</li>
          <li>Potential for leadership and innovation.</li>
          <li>Alignment with the organization's values.</li>
          <li>Resilience and perseverance in overcoming challenges.</li>
        </ul>
      </div>
      <br></br>
      <br></br>
      <ChatDei></ChatDei>
    </div>
  );
};
export default DashboardDei;
