import React, { useState } from "react";
import styles from "./academic.module.css"
import HeaderAcademic from "./headeracademic";
import FooterAcademic from "./footeracademic";
const ProfileManagement = () => {
    return (
        <div>
            <HeaderAcademic></HeaderAcademic>
            <div className={[styles.profile_container]}>
                <h1>Academic User Profile</h1>
                <button className={[styles.profile_container_button]}><a className={[styles.home_section_url]} href="ProfileManagementEdit">Edit</a></button>
                <div className={[styles.profile_info]}>
                    <h2>Personal Information</h2>
                    <p><strong>Name:</strong> John Doe</p>
                    <p><strong>Email:</strong> john.doe@example.com</p>
                    <p><strong>Phone:</strong> +1 123-456-7890</p>
                </div>
                <div className={[styles.educational_background]}>
                    <h2>Educational Background</h2>
                    <p><strong>Degree:</strong> Master of Science</p>
                    <p><strong>University:</strong> XYZ University</p>
                    <p><strong>Year:</strong> 2022</p>
                </div>
                <div className={[styles.educational_background]}>
                    <h2>Research Experience</h2>
                    <p><strong>Position:</strong> Research Assistant</p>
                    <p><strong>Institution:</strong> ABC Research Institute</p>
                    <p><strong>Duration:</strong> 2020 - 2022</p>
                    <p><strong>Research Topic:</strong> Data Analysis</p>
                </div>
                <div className={[styles.preferences]}>
                    <h2>Preferences</h2>
                    <p><strong>Preferred Field of Study:</strong> Computer Science</p>
                    <p><strong>Positions Offering:</strong> Software Engineer</p>
                </div>

            </div>
            <FooterAcademic></FooterAcademic>
        </div>
    );
};
export default ProfileManagement;
